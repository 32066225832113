import React, { useState, useEffect, useRef } from 'react'
import "./BeatDEuclid.css"
import Navbar from '../Navbar/Navbar.js';
import  WAVES from 'vanta/dist/vanta.waves.min'
import Footer from '../Footer/Footer';
const Photography = (props) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x96828,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <>
      <Navbar/>
    <div className='eventsinfo' >
    <div className='eventsinfo-body' ref={myRef}>
    
      <div className='py-20'>

      <div className='event-name'>Photography</div>
    <div className="cs-container" >
      <div className='left-side'>
      <div className="problem-container">
      <h1 className="problem-title">Problem Statement</h1>
        <p className="problem-content">
         <i>Landfills are places where our community's garbage and waste end up. It's like a designated area where we dispose of the things we no longer need, from household items to industrial waste. capture this in pictures to show what these places look like.
          <br/>
          <b>Task:</b><br/>
          1)   Capture a photograph that reveals the reality of these places near your home.<br/>
          2)   Write a small write-up highlighting the following points with a minimum of 250 words:<br/>
          <ul style={{ listStyleType: 'disc' }}>
            <li style={{marginLeft:"1.5rem"}}>-> How do these landfills affect the nearby environment, neighborhoods, and the people living there?</li>
            <li style={{marginLeft:"1.5rem"}}>-> Bring attention to the challenges these landfills pose, such as pollution, habitat disruption, and the strain on local resources.</li>
            <li style={{marginLeft:"1.5rem"}}>-> Look for chances to improve things, like finding ways to manage waste sustainably and involving the community.</li>
          </ul>
</i>
        </p>
      </div>
       <div className="rules-container">
        <h1 className="rules-title">Rules and Guidelines:</h1>
        <p className="rules-content">
         <i><b>Eligibility: </b></i><br/>
         <i>1)  The competition is exclusively open to all college students.<br/>
            2)  Only original images, not having won prizes in prior competitions, are eligible for submission. <br/>
         </i>
          <i>Image Edits: </i> <br/>
          <i>1)   Excessive digital alterations that significantly change the content or context of the photograph are not permitted. <br/>
          2)   Watermarks and signatures on the photographs are not allowed.
</i>
          <br/><br/><i><b>Submission Guidelines: </b></i> <br/>
          <i>1)  Participants can only submit one photograph.  <br/>
2)  The photograph must be original and taken by the participant. <br/>
3)  Images should be in JPEG format with a minimum resolution of 1600 pixels wide for horizontal or 1600 pixels tall for vertical.  <br/>
4)  Submissions should align with the event's theme <br/>
5)  Complete and correct details on the photo submission page, including file name, participant's name, phone number, and enrolled college name. <br/>
</i>
<br/>
<i><b>Judging Criteria </b></i><br/>
<i>The total count of likes, shares, and comments on images uploaded to the Megalith Facebook page will contribute 30% to the overall score. The write-up will carry 30% weightage, Points awarded by a panel of judges will account for the remaining 40%.<br/> <br/>

The judging panel will evaluate each photograph, considering visual impact, composition, technical excellence, and incorporating unique features relevant to the problem statement.
</i>
        </p>
      </div>
      </div>
      <div className='right-side'>
      <div className="rules-container">
        <h1 className="rules-title">Result:</h1>
        <p className="rules-content">
         <i>The result will be declared during the closing ceremony.
The winning photos will stand out on Megalith's website, showcasing the exceptional talent of the winners
</i>
        </p>
      </div>
      <div className="prize-container">
        <h1 className="prize-title">Prize</h1>
        <p className="prize-content flex flex-col justify-center">
         <i>1st prize - 2500/-</i>
         <i>2nd prize - 1500/- </i>
         <i>3rd prize - 1000/-</i>
        </p>
      </div> 
      </div>
    </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
}

export default Photography