import { useEffect,useState } from 'react'
import { useParams,Link } from 'react-router-dom';
import axios from 'axios'
import './EmailVerify.css'
import Navbar from '../../Navbar/Navbar.js';
const EmailVerify = () => {
  const [validUrl,setValidUrl]=useState('');
  const param=useParams();
  useEffect(()=>{
    const verifyEmailUrl=async()=>{
      try {
        const url=`${process.env.REACT_APP_BASE_URL}/users/${param.id}/verify/${param.token}`;
        await fetch(url).then((res)=>{
          setValidUrl(true);
        });
      } catch (error) {
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  },[param])
    return (
      <div className='body'>
      <Navbar/>
      <div className="registration  " >
          <div className="registration-container">
              <div className="full-height justify-content-center">
                  <div className="flex justify-center items-center h-screen col-12 text-center align-self-center py-5">
                      <div className="registration-section pb-5 pt-5 pt-sm-2 text-center">
                          <div className="registration-card-3d-wrap mx-auto">
                              <div className="registration-card-3d-wrapper">
                                  <div className="registration-card-front">
                                      <div className="registration-center-wrap">
                                          <div className="registration-section text-center">
                                            {validUrl?(<form>
                                              <h2 className="mb-4 pb-3">Email Verfied Succesfully</h2>
                                              <Link to='/registration'><h6 className="mb-0 pb-3 text-white "><span className='bg-black py-2 mx-5 rounded-xl border-4 border-orange-400'>Log In </span></h6></Link>
                                              </form>):
                                              (<h2 className="mb-4 pb-3">404 Not Found</h2>)
                                              }
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
    )
  }
  
  export default EmailVerify;