
import './App.css';
import Footer from './Components/Footer/Footer';
import HeroSection from './Components/HeroSection/HeroSection';
import Mainpage from './Components/MainPage/Mainpage';
import Navbar from './Components/Navbar/Navbar';
import Home from './Home';


function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
