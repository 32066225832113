import React, { Component } from 'react'
import logo from '../../Assets/Images/megalogotheme.png'
import {motion} from 'framer-motion'
import { fadeIn } from '../../Assets/Images/Motions/variants'
import { FaBars, FaTimes } from 'react-icons/fa'
import './Navbar.css'

class Navbar extends Component  {
    state ={clicked : false};
    handleClick = () => {
        this.setState({clicked:
        !this.state.clicked})
        
    }
    
    render(){
      const handleLogOut = () => {
        localStorage.removeItem("token")
        window.location.reload();
      }
    const user=localStorage.getItem("token")
  return (
    <motion.div
    >
      <motion.nav 
      className="background-color flex bg-green ">
   <a style={{fontSize: 24}} href="/"><figure className="logo fixed_flex small">
        <img src={logo} alt="" />
        <figcaption>
            <h3 className="title text-2xl"> <em>Mega</em>lith</h3>
            {/* <h5 className="sub_title">Software</h5> */}
        </figcaption>
    </figure></a>
    <div className='flex z-20 '>
    <ul id='navbar' className={this.state.clicked ? "#navbar active":"#navbar"}>
        <li><a href="/Home" className="">Home</a></li>
        <li><a href="/events&workshop">Events & Workshops</a></li>
        <li><a href="/sponsors">Sponsors</a></li>
        <li><a href="/ourteam">Our Team</a></li>
      
        <li><a href="https://cap.megalith.co.in/">CAP </a></li>
        {(user)?(<li><a href={`/dashboard/${JSON.parse(localStorage.getItem("data"))._id}`}>Dashboard</a></li>):(  <li><a href="/registration">Registration</a></li>)}
        {(user)?(<li><a><button onClick={handleLogOut}>Log Out</button></a></li>):(<div></div>)}
    </ul>

    </div>
    <div id='mobile' onClick={this.handleClick} className='z-40'>
      <i id='bar'>
        {this.state.clicked ? <FaTimes/> : <FaBars/>}
       </i>
    </div>
</motion.nav>
    </motion.div>
  )
}
}

export default Navbar
// className={this.state.clicked ? <FaTimes/> : <FaBars/>}>