import React, { useEffect, useRef } from 'react';
 import { Html } from '@react-three/drei';
import './Loader.css'
import {motion} from 'framer-motion'
import { fadeIn } from '../../Assets/Images/Motions/variants';
import $ from 'jquery'
const Loader = () => {
  $('.count').each(function () {
    $(this).prop('Counter',0).animate({
        Counter: $(this).text()
    }, {
        duration: 15000,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
});

  return (
    <Html className=' w-full flex justify-center items-center' >

    <div className='flex flex-col justify-center items-center'>
      <div className="example-loader-page">	
    <div className="determiniate-loader">
      <svg viewBox="-200 -200 400 400">
  
        <defs>
          <linearGradient id="bts-red" gradientUnits="userSpaceOnUse" x1="13.6318" y1="9.0277" x2="61.6571" y2="66.262">
            <stop offset="0" style={{ stopColor: "#EC1C5B" }} />
            <stop offset="1" style={{ stopColor: "#EA3F96" }} />
          </linearGradient>
        </defs>
  
        <g className="base">
          <circle r="160" />
        </g>
        <g className="filler">
          <circle strokeWidth={6} strokeLinecap="round" strokeLinejoin="round" r="160" />
        </g>
  
      </svg>
  
      <div className="loader-status">
        <span className="count" >100</span>%
      </div>
     
    </div>
   
  </div>
  <motion.div className='loading-note  flex flex-col justify-center items-center m-6 ' 
     variants={fadeIn('up', 5)}
     initial='hidden'
     whileInView={'show'}
     viewport={{ once: false, amount: 0.3 }}
  
  > 
         <span className='m-2'>It is taking a while </span> 
        you can visit to our home page
        </motion.div>
    </div>
   
      </Html>
  );
};

export default Loader;




// import React from 'react'
// import { Html } from '@react-three/drei';
// const Loader = () => {
//   return (
    
//   )
// }

// export default Loader;

