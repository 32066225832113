import {Suspense,useState,useEffect} from 'react'
// import { Canvas } from '@react-three/fiber'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Loader from './Loader';
import Space from './Space3d';
import {motion} from 'framer-motion'
import { FaArrowRight } from "react-icons/fa";
import Popups from './Popups';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Navbar from '../Navbar/Navbar';

import '../MainPage/Mainpage.css'
import { Link, Link as LinkR } from 'react-router-dom';

// import * as THREE from 'three'
const CameraController = () => {
  const {camera,gl} = useThree();
    useEffect(() => {
      const controls = new OrbitControls(camera, gl.domElement);

      controls.minDistance = -50;
      controls.maxDistance = 30;
      return () => {
        controls.dispose();
      };
    }, [camera,gl]);
    return null;
  };

 const Mainpage = () => {
    

  const [currentStage, setCurrentStage] = useState(1);
    const adjustSpaceForScreenSize = () =>{
      let screenScale,screenPosition;
      let rotation=[0.1,4.7,0];

      if(window.innerWidth <768){
        screenScale = [0.9,0.9,0.9];
        screenPosition = [0,-35,0];
      }else{
        screenScale = [2,2,2];
        screenPosition = [0,0,0];
      }

      return [screenScale,screenPosition,rotation]
    }
    const [spaceScale,spacePosition,spaceRotation] = adjustSpaceForScreenSize();
  return (
    <section className='w-full h-screen relative'>
        <div 
          
         className='w-full h-screen absolute left-0 right-0 flex items-end justify-center text-black pb-20 '>
          {/* <Link to="/Home" type='button'> */}
        <motion.a
              to="/Home" type="button"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.2,
              delay: 1,
              ease: [0, 0.71, 0.2, 1.01]}}
              className='home-btn border-4 z-10 border-red-800 rounded-2xl py-3 px-20 bg-white text-2xl hover:bg-red-800 hover:border-white hover:text-white '
                            // className='home-btn border-4 z-10'
              > 

                      <Link to='/Home' type='button' className='btn-enter flex items-center justify-center text-2xl'>
                      <span className='px-2'> EXPLORE </span><FaArrowRight/>
                      </Link>
        </motion.a>
          {/* </Link> */}
        
        </div>
        {/* <Navbar/>ß */}
        <Canvas
            className={` w-full h-screen `}
            camera={{near:0.1,far:1000,position:[0,15,0]}}
        >

        <Suspense fallback={<Loader />}  >
          <CameraController />
            <directionalLight position={[-3,150,50]} intensity={3} />
            <ambientLight intensity={0} />
            <pointLight />
            <hemisphereLight />
            {/* <Loader/> */}
            <Space 
             position={spacePosition}
            //  scale={spaceScale}
            //   rotation={spaceRotation}
            
              />
        </Suspense>
        </Canvas>
    </section>
  )
}

export default Mainpage;
