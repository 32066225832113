import axios from 'axios'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import './ForgotPassword.css'
import Navbar from '../../Navbar/Navbar.js';
import toast, { Toaster } from 'react-hot-toast';
const ForgotPassword = () => {
	const [Email,setEmail]=useState('');
    const [msg,setMsg]=useState('');
    const [error,setError]=useState('');

    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const url=`${process.env.REACT_APP_BASE_URL}/password-reset`;
            const {data}=await axios.post(url,{Email});
            setMsg(data.message);
            setError('');
        } catch (error) {
            if(
                error.message &&
                error.response.status>=400 &&
                error.response.status<=500
            ){
                setError(error.response.data.error);
            }
        }
    };
	useEffect(()=>{
		notify();
	  },[msg,error]
	);
	const notify=()=>{
		if(msg){
		  toast.success(msg);
	}
	if(error){
		toast.error(error);
	}
};

  return (
    <div className='body'>
	<Navbar/>
	<Toaster position="top-center" reverseOrder={false}/>
    <div className="registration  " >
		<div className="registration-container">
			<div className="full-height justify-content-center">
				<div className="flex justify-center items-center h-screen col-12 text-center align-self-center py-5">
					<div className="registration-section pb-5 pt-5 pt-sm-2 text-center">
						<div className="registration-card-3d-wrap mx-auto">
							<div className="registration-card-3d-wrapper">
								<div className="registration-card-front">
									<div className="registration-center-wrap">
										<div className="registration-section text-center">
											<form onSubmit={handleSubmit}>
											<h4 className="mb-4 pb-3 text-2xl">Forgot Password</h4>
                                            <h6 className="mb-4 pb-3">Enter your Email to get Password Reset Link</h6>
											<div className="registration-form-group">
											<Icon icon="mdi:at" className="registration-input-icon" />
												<input type="email" name="Email" className="registration-form-style" placeholder="Your Email" id="logemail" onChange={(e)=>setEmail(e.target.value)} value={Email} required/>
											</div>	
											<input onClick={notify} type="submit" className="registration-btn mt-4 btn" value="Submit"/>
											</form>
				      					</div>
			      					</div>
			      				</div>
			      			</div>
			      		</div>
			      	</div>
		      	</div>
	      	</div>
	    </div>
	</div>
    </div>
  )
}

export default ForgotPassword