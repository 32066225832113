// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .home-btn{
    position: relative;
    display: inline-block;
} */

/* 
.home-btn::after,
.home-btn::before
{
    content: "EXPLORE";
    position:absolute;
    transition: all 400ms;
    top:0;
}
.home-btn:hover::after{
    top:-50px;
}
.home-btn::before{
    content: 'ENTER';
    top:50px;
}

.home-btn:hover::before{
    top:0; 
} */

.home-btn{

}
@keyframes glowing {
    0% {
        border-color: #fff;
        box-shadow: 0 0 3px #fff;
      background-color: #991b1b;
      box-shadow: 0 0 3px #991b1b;
      color: #fff;

    }
    33% {
        border-color: #991b1b;
        box-shadow: 0 0 3px #991b1b;
      background-color: #fff;
      box-shadow: 0 0 10px #fff;
      color: #000;
    }
66% {
        border-color: #991b1b;
        box-shadow: 0 0 3px #991b1b;
      background-color: #fff;
      box-shadow: 0 0 10px #fff;
      color: #000;
    }
    100% {
        border-color: #fff;
        box-shadow: 0 0 3px #fff;
      background-color: #991b1b;
      box-shadow: 0 0 3px #991b1b;
      color: #fff;
    }
  }
  .home-btn{
    animation: glowing 3000ms infinite;
  }`, "",{"version":3,"sources":["webpack://./src/Components/MainPage/Mainpage.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;;;;;;;;;;;;;;;;;;;GAmBG;;AAEH;;AAEA;AACA;IACI;QACI,kBAAkB;QAClB,wBAAwB;MAC1B,yBAAyB;MACzB,2BAA2B;MAC3B,WAAW;;IAEb;IACA;QACI,qBAAqB;QACrB,2BAA2B;MAC7B,sBAAsB;MACtB,yBAAyB;MACzB,WAAW;IACb;AACJ;QACQ,qBAAqB;QACrB,2BAA2B;MAC7B,sBAAsB;MACtB,yBAAyB;MACzB,WAAW;IACb;IACA;QACI,kBAAkB;QAClB,wBAAwB;MAC1B,yBAAyB;MACzB,2BAA2B;MAC3B,WAAW;IACb;EACF;EACA;IACE,kCAAkC;EACpC","sourcesContent":["/* .home-btn{\n    position: relative;\n    display: inline-block;\n} */\n\n/* \n.home-btn::after,\n.home-btn::before\n{\n    content: \"EXPLORE\";\n    position:absolute;\n    transition: all 400ms;\n    top:0;\n}\n.home-btn:hover::after{\n    top:-50px;\n}\n.home-btn::before{\n    content: 'ENTER';\n    top:50px;\n}\n\n.home-btn:hover::before{\n    top:0; \n} */\n\n.home-btn{\n\n}\n@keyframes glowing {\n    0% {\n        border-color: #fff;\n        box-shadow: 0 0 3px #fff;\n      background-color: #991b1b;\n      box-shadow: 0 0 3px #991b1b;\n      color: #fff;\n\n    }\n    33% {\n        border-color: #991b1b;\n        box-shadow: 0 0 3px #991b1b;\n      background-color: #fff;\n      box-shadow: 0 0 10px #fff;\n      color: #000;\n    }\n66% {\n        border-color: #991b1b;\n        box-shadow: 0 0 3px #991b1b;\n      background-color: #fff;\n      box-shadow: 0 0 10px #fff;\n      color: #000;\n    }\n    100% {\n        border-color: #fff;\n        box-shadow: 0 0 3px #fff;\n      background-color: #991b1b;\n      box-shadow: 0 0 3px #991b1b;\n      color: #fff;\n    }\n  }\n  .home-btn{\n    animation: glowing 3000ms infinite;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
