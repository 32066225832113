import React from 'react'
import {Link} from "react-router-dom";
import './Workshop.css';
import Navbar from '../Navbar/Navbar.js';
import '../Events/Events.css'
import Footer from '../Footer/Footer.js';
const Workshop = () => {
  return (
    <>
      <div className='body'>
      <Navbar/>
      <div className='heading'>WORKSHOP</div>
      <section className="workshop-cards-wrapper">
      <div className="card-grid-space">
      <div className=" workshop-card workshop1">
        <div>
          <h1 className='event-title'>Revit</h1>
          
         
        </div>
      </div>
    </div>
    <div className="card-grid-space">
      <div className="workshop-card workshop2" >
        <div>
          <h1 className='event-title'>Staad Pro</h1>
         
        </div>
      </div>
    </div>
  </section>
  <Footer/>
      </div>
  </>
  )
}

export default Workshop