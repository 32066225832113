import React, { useState, useEffect, useRef } from 'react'
import "./BeatDEuclid.css"
import Navbar from '../Navbar/Navbar.js';
import  WAVES from 'vanta/dist/vanta.waves.min'
import Footer from '../Footer/Footer';
const GreenCanvas = (props) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x96828,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <>
      <Navbar/>
    <div className='eventsinfo' >
    <div className='eventsinfo-body' ref={myRef}>
    
      <div className='py-20'>

      <div className='event-name'>Green Canvas</div>
    <div className="cs-container" >
      <div className='flex text-center items-center justify-center text-4xl mt-8 underline greencanvas-title-info'><b>PHARMACEUTICAL WASTEWATER MANAGEMENT</b>

</div>
      <div className='left-side'>
      <div className="information-container">
        <h1 className="information-title">Information</h1>
        <p className="information-content">
         <i>Pharmaceutical wastewater management is a critical aspect of environmental management, addressing the complex challenges posed by the discharge of effluents from pharmaceutical manufacturing processes. As the pharmaceutical industry plays a pivotal role in healthcare, generating wastewater containing various pharmaceutical compounds demands careful attention. These compounds, often potent and persistent, can have adverse effects on aquatic ecosystems and public health if not managed effectively. The management of pharmaceutical wastewater involves comprehensive treatment strategies to mitigate environmental impact, comply with regulations, and safeguard water resources. This field encompasses advanced technologies, stringent regulatory frameworks, and a commitment to sustainable practices, aiming to strike a balance between pharmaceutical production and environmental protection.
          
         <br/><h4 className='underline flex justify-center text-2xl my-6'><b>
         CURRENT SCENARIO
          </b></h4> <br/>

The current global scenario is marked by a dynamic interplay of various factors, encompassing health, technology, geopolitics, and the environment. The ongoing efforts to address and recover from the COVID-19 pandemic continue to shape daily life, with vaccination campaigns, public health measures, and economic recovery plans at the forefront. Advancements in technology, particularly in artificial intelligence and renewable energy, are influencing industries and societies, offering both opportunities and challenges. Geopolitically, shifts in alliances, trade dynamics, and climate change negotiations are contributing to a complex and interconnected world. Additionally, environmental concerns, such as climate change and biodiversity loss, are prompting increased attention and calls for sustainable practices across sectors. The current scenario underscores the importance of adaptability, resilience, and global cooperation to navigate the multifaceted challenges and opportunities defining the present era.
Feeding 10 billion people by 2050 will require a 50% increase in agricultural production, (which consumes 70% of the resource today), and a 15% increase in water withdrawals. Besides this increasing demand, the resource is already scarce in many parts of the world. Estimates indicate that over 40% of the world's population lives in water-scarce areas, and approximately ¼ of the world’s GDP is exposed to this challenge. By 2040, an estimated one in four children will live in areas with extreme water shortages. Water security is a major – and often growing –challenge for many countries today.
To strengthen water security against this backdrop of increasing demand, water scarcity, growing uncertainty, greater extremes, and fragmentation challenges, clients will need to invest in institutional strengthening, information management, and (natural and man-made) infrastructure development. Institutional tools such as legal and regulatory frameworks, water pricing, and incentives are needed to allocate better, regulate, and conserve water resources. Information systems are needed for resource monitoring, decision-making under uncertainty, systems analyses, and hydro-meteorological forecasts and warnings. Investments in innovative technologies for enhancing productivity, conserving and protecting resources, recycling stormwater and wastewater, and developing non-conventional water sources should be explored in addition to seeking opportunities for enhanced water storage, including aquifer recharge and recovery. Ensuring the rapid dissemination and appropriate adaptation or application of these advances will be key to strengthening global water security.

         </i>
        </p>
      </div>
      <div className="problem-container">
      <h1 className="problem-title">Problem Statement</h1>
        <p className="problem-content">
         <i>Water Scarcity has now become a growing problem in this fast-paced developing world. Efficient use of water resources, preventing its wastage is necessary for water conservation. We at MEGALITH expect the participants to come up with efficient ways of Pharmaceutical Waste Water management and its efficient use. <br/>

 <h4 className='mt-6'>What is Expected from the participants:</h4> 
<br/>
<div className='  ml-8 py-2'>

<li className=''>1)  Analyse how water is affected by pharmaceutical waste and what is its impact on the environment. (20 points) </li> 
</div>
<div className='  ml-8 py-2'>

<li className=''>2)  What innovative wastewater treatment technologies are currently in use, and how do they compare in terms of efficiency, cost, and environmental impact? (10 points)</li> 
</div>
<div className='  ml-8 py-2'>

<li className=''>3)  Draw a comparison with the developed countries in the ways and strategies they use for efficient pharmaceutical wastewater management. (20 points) </li> 
</div>
<div className='  ml-8 py-2'>

<li className=''>4)  Propose new strategies to address this issue of pharmaceutical wastewater and ensure sustainable solutions. (20 points) </li> 
</div>
<div className='  ml-8 py-2'>

<li className=''>5)  Explore the integration of machine learning algorithms in  Pharmaceutical wastewater treatment processes. How can AI optimise treatment efficiency, reduce energy consumption, and enhance overall plant performance? (20 points)</li> 
</div>
<div className='  ml-8 py-2'>

<li className=''>6)  Propose new government initiatives or reforms on existing government policies to use efficient techniques of pharmaceutical wastewater management. (10 points) </li> 
</div>

</i>
        </p>
      </div>
   
      
       <div className="rules-container">
        <h1 className="rules-title">Rules</h1>
        <p className="rules-content">
         <i>1)Teams must consist of a minimum of 2 and a maximum of 5 participants. <br/>

2) The event will be conducted in two phases:
 a. Online submission of abstract 
 b. Final presentation by shortlisted teams at IIT Kharagpur.<br/>

3) Shortlisted teams from the abstract submission round have to present their ideas in the form of PowerPoint presentations during Megalith 2024 at IIT Kharagpur.<br/>

4) The abstract should be submitted with a minimum font size of 11 and single line spacing and must not exceed 1 page.
<br/>
5) The abstract should be supported with valid references.
<br/>
6) Relevant statistics can be added to support your claim (you may add one extra page to include stats, and images and hyperlink them wherever required).
<br/>
7) The time limit for the final presentation is 8 + 2 minutes. After 8 minutes there will be a buzzer which will be rung to notify the participants that 2 more minutes are left for the completion of the presentation.
 <br/>
8) The decision of judges shall be final and binding.
<br/>
9) The Presentation file (abstract) should be submitted at megalith2019.iitkgp@gmail.com with the file name as Teamleadername_GreenCanvas
<br/><br/><b>
JUDGING CRITERIA:
</b>
<br/><br/>
1)  Presentation with Unique and relevant solutions will be prioritised over others <br/>
2)  The presentation must include answers to all the questions in the PS. <br/>
3)  Teams will also be judged based on the Involvement of all the participants of the team, the way of presentation, and the time limit. <br/><br/>
Solutions to all the ques - 80% <br/>
Presentation style, slides, teamwork, time limit- 20% <br/>
</i>
        </p>
      </div>
      </div>
      <div className='right-side'>
      
      <div className="prize-container">
        <h1 className="prize-title">Prize</h1>
        <p className="prize-content flex flex-col justify-center">
         <i>1st prize - 8000/-</i>
         <i>2nd prize - 5000/- </i>
         <i>3rd prize - 3000/-</i>
        </p>
      </div> 
      </div>
    </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
}

export default GreenCanvas