// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/workshop.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-wrapper-EW{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    padding: 4rem;
    margin: 0 auto;
    width: max-content;
    height:100vh;
  }
  @media (max-width:700px) {
    .cards-wrapper-EW{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4rem;
        padding: 4rem 0;
        margin-top: 3rem;
        width: max-content;
      }

  }
  .cards-title-EW{
    font-weight: 900;
  }

  .workshop{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Events_Workshop/EventsWorkshop.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kCAAkC;IAClC,cAAc;IACd,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,YAAY;EACd;EACA;IACE;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,mBAAmB;QACnB,kCAAkC;QAClC,cAAc;QACd,eAAe;QACf,gBAAgB;QAChB,kBAAkB;MACpB;;EAEJ;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,yDAAyD;IACzD,4BAA4B;EAC9B","sourcesContent":[".cards-wrapper-EW{\n\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 4rem;\n    padding: 4rem;\n    margin: 0 auto;\n    width: max-content;\n    height:100vh;\n  }\n  @media (max-width:700px) {\n    .cards-wrapper-EW{\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        grid-template-columns: 1fr 1fr 1fr;\n        grid-gap: 4rem;\n        padding: 4rem 0;\n        margin-top: 3rem;\n        width: max-content;\n      }\n\n  }\n  .cards-title-EW{\n    font-weight: 900;\n  }\n\n  .workshop{\n    background-image: url('../../Assets/Images/workshop.jpg');\n    background-repeat: no-repeat;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
