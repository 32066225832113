import React from 'react'
import './Footer.css'
import { FaFacebook, FaFacebookSquare, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
const Footer = () => {
  return (
    <main>
<footer className='footer z-10 p-6 text-white'>
    <section className="flex padding_1x flex-col">
      <h2 className='text-semibold text-5xl pb-3 font-[Afacad]'>Follow Us</h2>
        <div className='py-4 flex flex-row justify-between' >
        <a  href="https://www.facebook.com/megalithkgp" title="Facebook">
            {/* <i className="fa fa-3x fa-facebook small text-white" /> */}<FaFacebookSquare className='text-white text-4xl'/>
        </a>
        <a href="https://www.instagram.com/iitkgp.megalith/" title="Instagram"><FaInstagram className='text-white text-4xl'/></a>
        <a href="https://www.youtube.com/@MegalithCoInIITKgp/videos" title="Youtube"><FaYoutube className='text-white text-4xl'/></a>
        <a href="https://www.linkedin.com/company/megalith-iit-kharagpur/mycompany/" title="LinkedIn"><FaLinkedin className='text-white text-4xl'/></a>
        </div>
    </section>
    <section className=" flex  flex-col text-2xl pb-10 pt-2 px-2">
        <h3 className='text-semibold text-4xl pb-3'>Contact Us</h3>
        <div className='contacts flex flex-row py-4'>
          <li className='name-number mx-5 p-3 rounded-xl'>
          Aditya Pandey - <span className='numbers'>+91 6394433068</span> 
          </li>
          <li className='name-number mx-5 p-3 rounded-xl'>
          Aditya Sharma - <span className='numbers'>+91 7014493349</span> 
          </li>
          <li className='name-number mx-5 p-3 rounded-xl'>
          Challa Naga Sujan - <span className='numbers'>+91 8143637374</span> 
          </li>
        </div>
    </section>
    
</footer>

</main>
  )
}

export default Footer
