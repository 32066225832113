import React from 'react';
import './Competitions.css';
import {motion } from 'framer-motion'
import { fadeIn } from '../../Assets/Images/Motions/variants';
// Define the card data array
const cards = [
  { 
    title: 'Green Canvas', 
    copy: 'The purpose of Green Canvas is to encourage participants to come up with innovative and creative ways to mitigate effects of pollution and restore greenery', 
    button: 'More details' 
  },
  { 
    title: 'Rotolare', 
    copy: "Quantum Thrill Quest: Unleash an energy-efficient, interactive roller coaster with sustainable design, personalized rides, and cutting-edge technology.", 
    button: 'More details' 
  },
  { 
    title: 'Beat-d-Euclid', 
    copy: 'This event encourages the students to showcase their immense talent in the world of geometry with their amazing power of imagination.    ', 
    button: 'More details' 
  },
  { 
    title: 'Criar', 
    copy: "Criar: Megalith's flagship event challenges teams to build structures overnight, fostering teamwork and creativity.", 
    button: 'More details' 
  }
];

// Create a Card component
const Card = ({ title, copy, button }) => {
  return (
    <div className="card">
      <div className="content">
        <h1 className="competition_title uppercase text-[#e8f08f] text-xl">{title}</h1>
        <p className="copy">{copy}</p>
        <a href="/events"></a>
        <button className='btn'>{button}
        </button>
      </div>
    </div>
  );
};

// Create a PageContent component that maps over the cards array to render Card components
const PageContent = () => {
  return (
    <motion.div className="page-content"
    variants={fadeIn('down', 0.3)}
    initial='hidden'
    whileInView={'show'}
    viewport={{ once: false, amount: 0.3 }}
    >
      {cards.map((card, index) => (
        <Card key={index} title={card.title} copy={card.copy} button={card.button}  />
      ))}
    </motion.div>
  );
};

// Export the PageContent component
export default PageContent;