// import React from "react";
// import "./Ourteam.css";
// import Image1 from "../../Assets/Images/hp1.jpg";
// import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
// import {FaLinkedin, FaXTwitter } from "react-icons/fa6"
// import Navbar from "../Navbar/Navbar.js";

// const Ourteam = () => {
//   return (
//     <div className="megalithteam bg-gradient-to-r from-[#482121] from-0% via-[#7A3E3E] via-55% to-[#EEEEEE] to-100% ">
//       {/* <Navbar/> */}
//       <h1 className="ourteam-title"> OUR TEAM</h1>
//       <div className="ourteamcard">
//         <div className="container">
//           <div className="our-team">
//             <div className="pic">
//               <img width="100px" src={Image1} alt="" />
//             </div>
//             <div className="team-content">
//               <h3 className="title">HOD</h3>
//               <span className="post"> Design Team</span>
//             </div>
//             <ul className="social">
//               <li>
//                 <a href="#" className="fab fa-facebook"><FaFacebook/></a>{" "}
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//               </li>
//             </ul>
//           </div>
//           <div className="our-team">
//             <div className="pic">
//               <img width="100px" src={Image1} alt="" />
//             </div>
//             <div className="team-content">
//               <h3 className="title">Chairperson</h3>
//               <span className="post"> Design Team</span>
//             </div>
//             <ul className="social">
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//               </li>
//             </ul>
//           </div>
//           <div className="our-team">
//             <div className="pic">
//               <img width="100px" src={Image1} alt="" />
//             </div>
//             <div className="team-content">
//               <h3 className="title"> Chairperson</h3>
//               <span className="post"> Chairperson</span>
//             </div>
//             <ul className="social">
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//               </li>
//               <li>
//                 {" "}
//                 <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//               </li>
//             </ul>
//           </div>
//         </div>
//         <div className="ourteamcard">
//           <div className="container">
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> VP</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Treasurer</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="ourteamcard">
//           <div className="container">
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Executive Advisor</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Executive Advisor</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="ourteamcard">
//           <div className="container">
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="ourteamcard">
//           <div className="container">
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="ourteamcard">
//           <div className="container">
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//             <div className="our-team">
//               <div className="pic">
//                 <img width="100px" src={Image1} alt="" />
//               </div>
//               <div className="team-content">
//                 <h3 className="title"> Hari Prasad</h3>
//                 <span className="post"> Design Team</span>
//               </div>
//               <ul className="social">
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-facebook"><FaFacebook/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaXTwitter/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-twitter"><FaLinkedinIn/></a>
//                 </li>
//                 <li>
//                   {" "}
//                   <a href="#" className="fab fa-instagram"><FaInstagram/></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Ourteam;


import React from "react";
import "./Ourteam.css";
import Image1 from "../../Assets/Images/hp1.jpg";
import { FaFacebook, FaInstagram, FaLinkedinIn,FaGlobe } from "react-icons/fa";
import Navbar from "../Navbar/Navbar.js";
import Footer from "../Footer/Footer";
import HOD from './Photos/dhrubajyoti_sen.jpeg'
import Chairperson1 from './Photos/aniruddha_sengupta.jpeg'
import Chairperson2 from './Photos/mohammad_saud_afzal.jpeg'
import VP from './Photos/Kunal.jpg'
import Shoaib from './Photos/Shoaib.jpg'
import Lilesh from './Photos/lilesh.jpg'
import Aman from './Photos/Aman.jpg'
import Mitesh from './Photos/Mitesh Sahu.jpg'
import Nakshatra from './Photos/Nakshatra.jpg'
import Samprit from './Photos/Samprit.jpg'
import Shalin from './Photos/Shalin.JPG'
import Sandeep from './Photos/Sandeep.jpg'
import Naman from './Photos/Naman.jpg'
import Debabrata from './Photos/Debabrata.jpg'
import Tiyas from './Photos/Tiyas.jpg'
import Ephy from './Photos/Ephy.JPG'
import Alok from './Photos/Alok.jpg'
import Shivangi from './Photos/Shivangi.jpg'
import Anjana from './Photos/Anjana.jpg'
import Rounak from './Photos/Rounak.jpg'
import Sujan from './Photos/Sujan.jpg'
import Soubhik from './Photos/Soubhik.jpg'
import Soham from './Photos/Soham.jpg'
import Adrij from './Photos/Adrij.jpg'
import Shikhar from './Photos/Shikhar Gautam_.jpg'
import Piyush from './Photos/Piyush.jpg'
import Sharma from './Photos/Sharma.jpg'
import Harsh from './Photos/Harsh.jpg'
import Aditya from './Photos/Aditya.jpg'
import Bhagyashree from './Photos/Bhagyashree.jpg'
import Pooja from './Photos/Pooja.jpg'
import Aayushi from './Photos/Aayushi.jpg'
import Utkarsh from './Photos/Utkarsh.jpeg'
import Kaushikee from './Photos/kaushikee.jpeg'
import Dheeraj from './Photos/Dheeraj.jpg'
import Mohan from './Photos/Islavath.jpeg'
import Jay from './Photos/Jay.jpg'
import Meghana from './Photos/meghna.jpg'
import Sonu from './Photos/Sonu.jpg'
import Sunetra from './Photos/Sunetra.jpg'
import Shrewa from './Photos/shrewa.jpg'
import Suchismita from './Photos/Suchismita.jpg'
import Jiya from './Photos/Jiya.jpg'
import Rupam from './Photos/Rupam.jpg'
import Pratush from './Photos/Pratush.jpg'
import Gnaneswar from './Photos/Gnaneswar.jpg'
import Abhijeet from './Photos/Abhijeet.png'
import Pranathi from './Photos/Pranathi.jpg'
import Yashika from './Photos/Yashika.jpg'
import Kartheek from './Photos/Kartheek.jpg'
const Ourteam = () => {
  return (
    <>
      <Navbar/>
    <div className="megalithteam  bg-gradient-to-r from-[#482121] from-0% via-[#7A3E3E] via-55% to-[#EEEEEE] to-100% ">
      <h1 className="ourteam-title"> OUR TEAM</h1>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={HOD} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Prof. Dhrubajyoti Sen</h3>
              <span className="post">Head of Department</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.iitkgp.ac.in/department/CE/faculty/ce-djsen" className="fab fa-facebook"><FaGlobe/></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Chairperson1} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Prof. Aniruddha Sengupta</h3>
              <span className="post">Chairperson</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.iitkgp.ac.in/department/CE/faculty/ce-sengupta" className="fab fa-facebook"><FaGlobe/></a>
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Chairperson2} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Prof. Mohammmad Saud Afzal</h3>
              <span className="post"> Chairperson</span>
            </div>
            <ul className="social">
              <li>
              <a target="blank" href="https://www.iitkgp.ac.in/department/CE/faculty/ce-saud" className="fab fa-facebook"><FaGlobe/></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center"> <h2 className="ourteam-title">EXECUTIVE BODY</h2></div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={VP} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Kunal Kumar</h3>
              <span className="post">Vice-President</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100008788572995" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/kunal-kumar-6093a321a/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Shoaib} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Mohammad Shoaib</h3>
              <span className="post">Treasurer</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100011371895874" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/shoaib-mohammad-65ab87217" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
        <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Lilesh} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Lilesh Bhuskute</h3>
              <span className="post">Executive Advisor</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/lilesh.bhuskute.1" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/lilesh-bhuskute-5671bb1b2/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Utkarsh} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Utkarsh Gupta</h3>
              <span className="post">Executive Advisor</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100056537050626" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/utkarsh-gupta-756b64219" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      
     <div className="flex items-center justify-center"> <h2 className="ourteam-title"> COORDINATORS</h2></div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Bhagyashree} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Bhagyashree Sharma</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100009439655736" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/bhagyashree-sharma-7580aa220/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Pratush} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Pratush Tiwari</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/pratush.tiwari.18" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/pratush-tiwari-8b02a9218/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Debabrata} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title"> Debabrata Sarkar</h3>
              <span className="post">Events Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/debabrata.sarkar.92167789" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/debabrata-sarkar-88105421a" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Kaushikee} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Kaushikee Biswas</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100058787674565" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="www.linkedin.com/in/kaushikee-biswas-civil-kgpian" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="ourteamcard">
        <div className="container">
        <div className="our-team">
            <div className="pic">
              <img width="100px" src={Aayushi} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Aayushi Priya</h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/aayushhhiiiii" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/aayushi-priya-586b29207" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Sonu} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Sonu Kumar Rai</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100009529621145" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/sonu-kumar-rai-971ba81ba" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Jay} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Jay Nagwani</h3>
              <span className="post">Sponsership and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/jay.nagwani.1" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/jay-nagwani-0b0a31207/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      <div className="flex items-center justify-center"> <h2 className="ourteam-title"> CORE TEAM HEADS</h2></div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Aditya} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Aditya Pandey</h3>
              <span className="post">Sponsership and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100077353116042" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/adityapandeyiitkgp" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Pooja} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Pooja Saini</h3>
              <span className="post">Sponsership and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100076298028648&sk=about&section=year-overviews" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/pooja-saini-0919b3244" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Abhijeet} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Abhijeet Nigam</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/abhijeet.nigam.5682" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/abhijeet-nigam-605967223" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Naman} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title"> Naman Shankar</h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100073325747138" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/naman-shankar-2a66b0238" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Piyush} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Piyush Yadav</h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100082914117459" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/piyush-yadav-073ab0244/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Rounak} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Rounak Das</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100075484467779" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/rounak-das-b07b62244" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Shivangi} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Shivangi Singh</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100075163550918" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/shivangi-singh-30aba0244" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Sujan} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Challa Naga Sujan</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/nagasujan.challa/" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/challa-naga-sujan-5024aa231/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Meghana} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Paligiri Meghana</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100075515588109" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/meghana-paligiri-734aaa244" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Mohan} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title"> Islavath Mohan Naik</h3>
              <span className="post">Events Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100013401311744" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/mohan-naik-942569228" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Sharma} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Aditya Sharma</h3>
              <span className="post">Events Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/people/Aditya-Sharma/100002888868638/" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/aditya-sharma-356671233/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Harsh} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Harsh Kumar</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100074162357780" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/harsh-kumar-52480b241" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Anjana} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title"> Anjana Jawahar</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/anjana.jawahar.9/" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/anjana-jawahar-a28ba8204/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Kartheek} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Kartheek Valeti</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100074162357780" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/harsh-kumar-52480b241" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">  <h2 className="ourteam-title"> CORE TEAM MEMBERS</h2></div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Alok} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Alok Anand </h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100008408595358&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/alok-anand-5490b0257" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Rupam} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Rupam Mahato</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/rupam.mahato.378?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                <a target="blank" href="https://www.linkedin.com/in/rupam-mahato-415aa3178" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
            </ul>
          </div>
       
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Dheeraj} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Sabavath Dheeraj Chowhan</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100007246215699&mibextid=LQQJ4d" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/dheeraj-chowhan-b84758259" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Sunetra} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Sunetra Pratihar</h3>
              <span className="post">Web Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/sunetra.pratihar.5?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/sunetra-pratihar-6496ba22b" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Pranathi} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Taddi Venkata Pranathi Gandhi</h3>
              <span className="post"> Web Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100094756901380" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/pranathi-taddi-662ab027b/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Sandeep} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title"> Sandeep Kumar Meena</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/jee.aspirant.58?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/sandeep-kumar-meena-6bab81256" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Suchismita} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Suchismita Sahoo </h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=61550090230775" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/suchismita-s-933069284" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Ephy} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Pulivarthi Ephraim EL Raj</h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/ephraimelraj.pulivarthi?mibextid=LQQJ4d" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/ephraim-el-raj-993676196" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
        <div className="our-team">
            <div className="pic">
              <img width="100px" src={Aman} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Aman Meena </h3>
              <span className="post">Publicity Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100089202870990&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/aman-meena-155880259" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Shalin} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Shalin Chakraborty</h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100057586608983" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a href="https://www.linkedin.com/in/shalin-chakraborty-36589921b/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Soham} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Soham Mallik</h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100057586608983" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/soham-mallik-4b11021a1" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Gnaneswar} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Karakavalasa Venkata Gnaneswar </h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/gnaneswar.karakavalasa?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/gnaneswar-karakavalasa-ab00201b4" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Nakshatra} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Nakshatra Piplad</h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087394675451&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/nakshatra-piplad-b90283254" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Soubhik} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Soubhik Kumar Shaw</h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>

                <a target="blank" href="https://www.facebook.com/profile.php?id=100094720243008" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>

                <a target="blank" href="https://www.linkedin.com/in/soubhik-kumar-s-1b721a12b/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Adrij} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Adrij Bhattacharya</h3>
              <span className="post">Sponsorship and Marketing Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/adrij.bhattacharya.35/" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="linkedin.com/in/adrij-bhattacharya-9180bb259" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Yashika} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Yashika</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087209785135&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/yashika-979318259" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Mitesh} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Mitesh Sahu </h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/aryan.meena.54922?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/mitesh-sahu-4780b7259" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Shikhar} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Shikhar Gautam</h3>
              <span className="post">Logistics Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087734051225" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/shikhar-gautam-039241271/" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Tiyas} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Tiyas Roy Choudhury</h3>
              <span className="post">Events Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100086543220504&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/tiyas-roychoudhury-900b53251" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Shrewa} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Shrewa Shree konika p bharati </h3>
              <span className="post">Events Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/shrewashree.dey?mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/shrewa-shree-konika-p-bharati-768457258" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ourteamcard">
        <div className="container">
          
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Jiya} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Jiya Gupta</h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087660277635&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/jiya-gupta-251744283" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Samprit} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Samprit Saha</h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087482497244&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/sampritsaha" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
                
                
              </li>
            </ul>
          </div>
          <div className="our-team">
            <div className="pic">
              <img width="100px" src={Image1} alt="" />
            </div>
            <div className="team-content">
              <h3 className="title">Gurram Hariprasad Chaitanya </h3>
              <span className="post">Design Team</span>
            </div>
            <ul className="social">
              <li>
                
                <a target="blank" href="https://www.facebook.com/profile.php?id=100087638168413&mibextid=ZbWKwL" className="fab fa-facebook"><FaFacebook/></a>
              </li>
              <li>
                
                
              </li>
              <li>
                
                <a target="blank" href="https://www.linkedin.com/in/hpiitkgp" className="fab fa-twitter"><FaLinkedinIn/></a>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </div>
      
      </div>
    </div>
      <Footer/>
      </>
  );
};

export default Ourteam;