import React, { useEffect,useState } from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { IoLocationSharp } from "react-icons/io5";
import { HiMiniIdentification } from "react-icons/hi2";
import './Dashboard_1.css';
import { MdAlternateEmail } from "react-icons/md";
import Footer from '../../Footer/Footer';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import Navbar from '../../Navbar/Navbar.js';
import toast, { Toaster } from 'react-hot-toast';

// import { Video, Hero, HeroBanner, HeroContent, HeroH1, HeroP, HeroSpan, Span, Img,Button, HeroButton, HeroP1, HeroSpan1 } from "./BannerElements";
const Dashboard1 = () => {
  const [msg,setMsg]=useState('');
  const [error,setError]=useState('');
  const [events,setEvents]=useState('');
  const [workshops,setWorkshops]=useState('');
  const [both,setBoth]=useState('');
  const [data,setData]=useState({ Email: "",Name: "" ,MobNumber:"",College:"",MegalithID:""})
  const response =localStorage.getItem("data")
  ? (localStorage.getItem("data")) : [];
  const details=JSON.parse(response);
  useEffect(()=>{
    setData({ Email:details.Email,Name:details.Name,MobNumber:details.MobNumber,College:details.College,MegalithID:details.MegalithID})
  },[data])

  // useEffect(() => {
  //   const fetchEventsData = async () => {
  //     try {
  //       const url=`${process.env.REACT_APP_BASE_URL}/events`;
  //       const {data:res1}=await axios.get(url,{Email:details.Email})
  //       setEvents(true);
  //     } catch (error) {
  //       setEvents(false);
  //     }
  //   };
  //   fetchEventsData();
  // }, [data]) 

  // useEffect(() => {
  //   const fetchWorkshopsData = async () => {
  //     try {
  //       const url=`${process.env.REACT_APP_BASE_URL}/workshops`;
  //       const {data:res2}=await axios.get(url,{Email:details.Email});
  //       setWorkshops(true)
  //     } catch (error) {
  //       setWorkshops(false)
  //     }
  //   };
  //   fetchWorkshopsData();
  // }, [])

  // useEffect(() => {
  //   const fetchBothData = async () => {
  //     try {
  //       const url=`${process.env.REACT_APP_BASE_URL}/eventsworkshops`;
  //       const {data:res3}=await axios.get(url,{Email:details.Email});
  //       setBoth(true);
  //     } catch (error) {
  //       setBoth(false)
  //     }
  //   };
  //   fetchBothData();
  // }, [])

  
  const EventsRegister=async(e)=>{
    e.preventDefault();
    try {
      const url=`${process.env.REACT_APP_BASE_URL}/events`;
      const {data:res1}=await axios.post(url,data);
      setMsg(res1.data.message);
      setError(res1.data.error);
    } catch (error) {
      setError(error.response.data.error)
    }
  }
  const WorkshopsRegister=async(e)=>{
    e.preventDefault();
    try {
      const url=`${process.env.REACT_APP_BASE_URL}/workshops`;
      const {data:res2}=await axios.post(url,data);
      setMsg(res2.data.message);
      setError(res2.data.error);
    } catch (error) {
      setError(error.response.data.error)
    }
  }
  const EventsandWorkshopsRegister=async(e)=>{
    e.preventDefault();
    try {
      const url=`${process.env.REACT_APP_BASE_URL}/eventsworkshops`;
      const {data:res3}=await axios.post(url,data);
      setMsg(res3.data.message);
      setError(res3.data.error);
    } catch (error) {
      setError(error.response.data.error)
    }
  }
  
  return (
    <div className="dashboard-page-content ">
    <Navbar/>
    <div className=" ">
      <div className="p-4">
        <div className="">
          <div className=" user_card w-full md:w-1/2 ">
            <div className="  bg-[#B31312] p-4 text-center text-white">
              <div className="  mb-4">
                <img
                  src="https://img.icons8.com/bubbles/100/000000/user.png"
                  className="rounded-full w-16 h-16 mx-auto"
                  alt="User-Profile"
                />
              </div>
              {/* <h6 className="text-3xl font-semibold">{state.Name}</h6> */}
              <h6 className="text-3xl font-semibold">{details.Name}</h6>
              <i className="feather icon-edit mt-4 text-2xl"></i>
            </div>
            <div className=" p-4">
              <h6 className=" information  text-3xl mb-5 pb-2 border-b font-semibold text-white">
                Information
              </h6>
              <div className="information_display grid grid-cols-3 gap-4">
                <div className="w-1/2 py-5 ">
                  <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                    <MdAlternateEmail className='mr-2 '/> Email
                  </p>
                  {/* <h6 className="text-white flex flex-row items-center text-xl">{state.Email}</h6> */}
                  <h6 className="text-white flex flex-row items-center text-xl">{details.Email}</h6>
                </div>
                <div className="w-1/2 py-5">
                  <p className=" information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                   <FaPhoneAlt className='mr-2'/> Phone
                  </p>
                  {/* <h6 className="text-white text-xl">{state.MobNumber}</h6> */}
                  <h6 className="text-white text-xl">{details.MobNumber}</h6>
                </div>
                <div className="w-1/2 py-5">
                  <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                   <HiMiniIdentification className='mr-2 '/> Megalith-Id
                  </p>
                  {/* <h6 className="text-white text-xl">{state.MegalithID}</h6> */}
                  <h6 className="text-white text-xl">{details.MegalithID}</h6>
                </div>
                <div className="w-1/2 py-5">
                  <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                   <IoLocationSharp className='mr-2 '/> State
                  </p>
                  {/* <h6 className="text-white text-xl">{state.State}</h6> */}
                  <h6 className="text-white text-xl">{details.State}</h6>
                </div>
                <div className="w-1/2 py-5">
                  <p className=" information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                   <IoLocationSharp className='mr-2 '/> City
                  </p>
                  {/* <h6 className="text-white text-xl">{state.City}</h6> */}
                  <h6 className="text-white text-xl">{details.City}</h6>
                </div>
                {/* <div className="w-1/2 py-5">
                  {(events)?(<h6 className="text-white text-xl">{msg}</h6>):(<div></div>)}
                  {(workshops)?(<h6 className="text-white text-xl">You have Succesfully Registered for Workshops</h6>):(<div></div>)}
                  {(both)?( <h6 className="text-white text-xl">You have Succesfully Registered for Events and Workshops</h6>):(<div></div>)}
                </div> */}
              </div>
            </div>
          </div>
          <div className="Button_container pb-10 ">

            <button onClick={()=>window.open("https://rzp.io/l/megalith-event","_blank")} className="btn-grad border-4 border-[#164863] text-[#000] bg-[#FFF] hover:border-[#FFF] hover:bg-[#B31312] hover:text-[#FFF]">
              <button onClick={EventsRegister}>Register for Events</button>
            </button>
            <button onClick={()=>window.open("https://rzp.io/l/megalith-workshop","_blank")} className="btn-grad border-4 border-[#164863] text-[#000] bg-[#FFF] hover:border-[#FFF] hover:bg-[#B31312] hover:text-[#FFF]">
              <button  onClick={WorkshopsRegister}>Register for Workshop</button>
            </button>
            <button onClick={()=>window.open("https://rzp.io/l/megalith-event&workshop","_blank")} className="btn-grad border-4 border-[#164863] text-[#000] bg-[#FFF] hover:border-[#FFF] hover:bg-[#B31312] hover:text-[#FFF]">
              <button  onClick={EventsandWorkshopsRegister}>Register for Both</button>
            </button>
        
          </div>
        </div>
      </div>
    </div>
  </div>
                                            
  )
}

export default Dashboard1