import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './PasswordReset.css'
import Navbar from '../../Navbar/Navbar.js';
import toast, { Toaster } from 'react-hot-toast';
const PasswordReset = () => {
	  const [validUrl,setValidUrl]=useState(false);
    const [Password,setPassword]=useState('');
    const [ConfirmPassword,setConfirmPassword]=useState('');
    const [msg,setMsg]=useState('');
    const [error,setError]=useState('');
    const param=useParams();
    const url=`${process.env.REACT_APP_BASE_URL}/password-reset/${param.id}/${param.token}`;
    useEffect(()=>{
        const verifyUrl=async()=>{
          try{
            await axios.get(url);
            setValidUrl(true);
          }catch(error){
            setValidUrl(false);
          }
        };
        verifyUrl();
    },[param,url]);
    const handleSubmit=async(e)=>{
      e.preventDefault();
      try {
        const {data}=await axios.post(url,{Password,ConfirmPassword});
        setMsg(data.message);
        setError(data.error);
        window.location='/registration';
      } catch (error) {
        if(
          error.response &&
          error.response.status>=400 &&
          error.response.status<=500
        ){
          setError(error.response.data.error);
        } 
      }
    }
    useEffect(()=>{
      notify();
    },[msg,error])
    const notify = () => 
    {
    if(msg){
      toast.success(msg);
    }
    if(error){
      toast.error(error);
    }
  }
  return (
    <div className='body'>
    <Navbar/>
    <Toaster position="top-center" reverseOrder={false}/>
    <div className="registration">
		<div className="registration-container">
			<div className="full-height justify-content-center">
				<div className="flex justify-center items-center h-screen col-12 text-center align-self-center py-5">
					<div className="registration-section pb-5 pt-5 pt-sm-2 text-center">
						<div className="registration-card-3d-wrap mx-auto">
							<div className="registration-card-3d-wrapper">
								<div className="registration-card-front">
									<div className="registration-center-wrap">
										<div className="registration-section text-center">
										{validUrl?
											(<form onSubmit={handleSubmit}>
											<h4 className="mb-4 pb-3 text-2xl">Reset Your Password</h4>
											<div className="registration-form-group">
                        <Icon icon="material-symbols:lock" className="registration-input-icon"/>
												<input type="password" name="Password" className="registration-form-style" placeholder="Enter new Password" id="logemail" onChange={(e)=>{setPassword(e.target.value)}} value={Password} required/>
											</div>
											<div className="registration-form-group">
                        <Icon icon="material-symbols:lock" className="registration-input-icon"/>	
												<input type="password" name="ConfirmPassword" className="registration-form-style" placeholder="Confirm Password" id="logemail" onChange={(e)=>{setConfirmPassword(e.target.value)}} value={ConfirmPassword} required/>
											</div>
											<input onClick={notify} type="submit" className="registration-btn mt-4 btn" value="Submit"/>
											</form>):
											(<h2 className="mb-4 pb-3">404 Not Found</h2>)
										}
				      					</div>
			      					</div>
			      				</div>
			      			</div>
			      		</div>
			      	</div>
		      	</div>
	      	</div>
	    </div>
	</div>
    </div>
  )
}

export default PasswordReset;