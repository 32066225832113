// import "./Sponsors.css";
// import { useState } from "react";

// const Sponsors = () => {
//   const sponsorsContent2022 = [
//     {
//       sponsorName: "S. K Samanta",
//       subTitle: "Title Sponsor",
//       sponsorDetails: "http://sksl.co.in/",
//       sponsorImage: "./2022/sksamanta.jpeg",
//       // sponsorImage: "./2022/sksamanta.jpeg",
//     },
//     {
//       sponsorName: "Fosroc",
//       subTitle: "Platinum Sponsor",
//       sponsorDetails: "https://www.fosroc.com/",
//       sponsorImage: "./2022/fosroc.png",
//     },
//     {
//       sponsorName: "Enkebee",
//       subTitle: "Events Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/enkebee.jpeg",
//     },
//     {
//       sponsorName: "Campus Times Pune",
//       subTitle: "Youth Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/campustimespune.jpeg",
//     },
//     {
//       sponsorName: "The Education Tree",
//       subTitle: "Youth Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/theeducationtree.jpeg",
//     },
//     {
//       sponsorName: "Youth Incorporated",
//       subTitle: "Youth Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/youthincorporated.jpeg",
//     },
//     {
//       sponsorName: "Campus Memories",
//       subTitle: "Merchandise Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/campusmemories.jpeg",
//     },
//     {
//       sponsorName: "The Statesman",
//       subTitle: "Newspaper Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/thestatesman.jpeg",
//     },
//     {
//       sponsorName: "India Blooms",
//       subTitle: "Newspaper Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/indiablooms.jpg",
//     },
//     {
//       sponsorName: "Aapka Times",
//       subTitle: "Newspaper Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/aapkatimes.jpeg",
//     },
//     {
//       sponsorName: "India College Fest",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/indiacollegefest.jpeg",
//     },
//     {
//       sponsorName: "Know A Fest",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/knowafest.jpg",
//     },
//     {
//       sponsorName: "Ohcampus",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/ohcampus.jpeg",
//     },
//     {
//       sponsorName: "Grab On",
//       subTitle: "Coupon Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/grabon.jpeg",
//     },
//     {
//       sponsorName: "FriendsFM",
//       subTitle: "Exclusive Radio Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/friendsfm.jpeg",
//     },
//     {
//       sponsorName: "Civil Shala",
//       subTitle: "Training Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/civilshala.jpg",
//     },
//     {
//       sponsorName: "Made Easy",
//       subTitle: "Education Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2022/madeeasy.jpeg",
//     },
//   ];

//   const sponsorsContent2023 = [
//     {
//       sponsorName: "Elegant Steel",
//       subTitle: "Title Sponsor",
//       sponsorDetails: "https://www.elegantsteels.com/",
//       sponsorImage: "./2023/elegant.png",
//     },
//     {
//       sponsorName: "Tata Steel",
//       subTitle: "Platinum Sponsor (Criar)",
//       sponsorDetails: "https://www.fosroc.com/",
//       sponsorImage: "./2023/tatasteel.png",
//     },
//     {
//       sponsorName: "S. K. Samanta",
//       subTitle: "Gold Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/sksamanta.jpeg",
//     },
//     {
//       sponsorName: "DCart Logistics",
//       subTitle: "Event Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/dcart.png",
//     },
//     {
//       sponsorName: "Enkebee",
//       subTitle: "Event Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/enkebee.jpeg",
//     },
//     {
//       sponsorName: "Aimil India",
//       subTitle: "Major Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/aimil.png",
//     },
//     {
//       sponsorName: "Unichem",
//       subTitle: "Major Sponsor",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/unichem.png",
//     },
//     {
//       sponsorName: "Fosroc",
//       subTitle: "Constructive Solutions Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/fosroc.jpg",
//     },
//     {
//       sponsorName: "Civil Center",
//       subTitle: "Workshop",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/civilcenter.png",
//     },
//     {
//       sponsorName: "Henry Harvin",
//       subTitle: "Events Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/henryharwin.png",
//     },
//     {
//       sponsorName: "Civil Shala",
//       subTitle: "Events Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/civilshala.png",
//     },
//     {
//       sponsorName: "Civil Guruji",
//       subTitle: "Education Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/civilguruji.png",
//     },
//     {
//       sponsorName: "Gateforum",
//       subTitle: "Education Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/gateforum.png",
//     },
//     {
//       sponsorName: "Mera Events",
//       subTitle: "Exclusive Ticket Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/meraevents.jpeg",
//     },
//     {
//       sponsorName: "Youth Incorporated",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/youthincorporated.jpeg",
//     },
//     {
//       sponsorName: "College India Fest",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/siliconindia.png",
//     },
//     {
//       sponsorName: "Silicon India",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/siliconindia.png",
//     },
//     {
//       sponsorName: "Campus Times Pune",
//       subTitle: "Online Media Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/campustimespune.jpeg",
//     },
//     {
//       sponsorName: "Grab On",
//       subTitle: "Coupon Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/grabon.jpeg",
//     },
//     {
//       sponsorName: "India Blooms",
//       subTitle: "Newspaper Partner",
//       sponsorDetails: "http://enkebee.com/",
//       sponsorImage: "./2023/indiablooms.jpg",
//     },
//   ];

//   const sponsorsContent2024 = [
//     {
//       sponsorName: "Coming Soon",
//     },
//   ];

//   const [show2022, setShow2022] = useState(false);
//   const [show2023, setShow2023] = useState(false);
//   const [show2024, setShow2024] = useState(false);
//   const [showsponsors, setShowsponsors] = useState(false);

//   return (
//     <div id="sponsors">
//       <h1 className="text-white text-6xl uppercase ">Sponsors</h1>
//       <div id="sponsors-display">
//         <div id="sponsors-buttons">
//           <button
//             onClick={() => {
//               setShow2022(!show2022);
//               setShow2023(false);
//               setShow2024(false);
//             }}
//             className="sponsors-btn"
//           >
//             2022
//           </button>
//           <button
//             onClick={() => {
//               setShow2022(false);
//               setShow2023(!show2023);
//               setShow2024(false);
//             }}
//             className="sponsors-btn"
//           >
//             2023
//           </button>
//           <button
//             onClick={() => {
//               setShow2022(false);
//               setShow2023(false);
//               setShow2024(!show2024);
//             }}
//             className="sponsors-btn"
//           >
//             2024
//           </button>
//         </div>
//         <div id="sponsors-line"></div>

//         <div id="sponsors-2022" className="sponsors-year-div">
//           {show2022 && (
//             <div>
//               <h1 className="sponsors-year-title">Sponsors of 2022</h1>
//               {sponsorsContent2022.map((sponsor, index) => {
//                 return (
//                   <div className="sponsor-card">
//                     <div className="sponsor-card-front">
//                       <div
//                         className="sponsor-card-front-body"
//                         // style={{backgroundImage: require(`${sponsor.sponsorImage}`)}}
//                       >
//                         <p>{sponsor.sponsorName}</p>
//                         <span>{sponsor.subTitle}</span>
//                         <img
//                           src={require(`${sponsor.sponsorImage}`)}
//                           className="sponsor-card-front-img"
//                         ></img>
//                       </div>
//                     </div>
//                     <div className="sponsor-card-back">
//                       <h5>{sponsor.subTitle}</h5>
//                       <button className="sponsors-km">
//                         <a href={sponsor.sponsorDetails}target="_blank" rel="noopener noreferrer" >Know More</a>
//                       </button>
//                       <img
//                         src={require(`${sponsor.sponsorImage}`)}
//                         className="sponsor-card-front-img"
//                       ></img>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           )}
//         </div>
//         <div id="sponsors-2023" className="sponsors-year-div">
//           {show2023 && (
//             <div>
//               <h1 className="sponsors-year-title">Sponsors of 2023</h1>
//               {sponsorsContent2023.map((sponsor, index) => {
//                 return (
//                   <div className="sponsor-card">
//                     <div className="sponsor-card-front">
//                       <div
//                         className="sponsor-card-front-body"
//                         // style={{backgroundImage: require(`${sponsor.sponsorImage}`)}}
//                       >
//                         <p>{sponsor.sponsorName}</p>
//                         <span>{sponsor.subTitle}</span>
//                         <img
//                           src={require(`${sponsor.sponsorImage}`)}
//                           className="sponsor-card-front-img"
//                         ></img>
//                       </div>
//                     </div>
//                     <div className="sponsor-card-back">
//                       <h5>{sponsor.subTitle}</h5>
//                       <button className="sponsors-km">
//                         <a href={sponsor.sponsorDetails}target="_blank" rel="noopener noreferrer" >Know More</a>
//                       </button>
//                       <img
//                         src={require(`${sponsor.sponsorImage}`)}
//                         className="sponsor-card-front-img"
//                       ></img>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           )}
//         </div>
//         <div id="sponsors-2024" className="sponsors-year-div">
//           {show2024 && (
//             <div>
//               <h1 className="sponsors-year-title">Coming Soon</h1>
//               {/* {sponsorsContent2024.map((sponsor, index) => {
//                 return (
//                   <div className="sponsor-card">
//                     <div className="sponsor-card-front">
//                       <div
//                         className="sponsor-card-front-body"
//                         // style={{backgroundImage: require(`${sponsor.sponsorImage}`)}}
//                       >
//                         <p>{sponsor.sponsorName}</p>
//                         <span>{sponsor.subTitle}</span>
//                         <img
//                           src={require(`${sponsor.sponsorImage}`)}
//                           className="sponsor-card-front-img"
//                         ></img>
//                       </div>
//                     </div>
//                     <div className="sponsor-card-back">
//                       <h5>{sponsor.subTitle}</h5>
//                       <button className="sponsors-km">
//                         <a href={sponsor.sponsorDetails}target="_blank" rel="noopener noreferrer" >Know More</a>
//                       </button>
//                       <img
//                         src={require(`${sponsor.sponsorImage}`)}
//                         className="sponsor-card-front-img"
//                       ></img>
//                     </div>
//                   </div>
//                 );
//               })} */}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Sponsors;

import "./Sponsors.css";
import { useState } from "react";
import Navbar from "../Navbar/Navbar.js";
import Footer from "../Footer/Footer";
function Sponsors() {
  const sponsorsContent2022 = [
    {
      sponsorName: "S. K Samanta",
      subTitle: "Title Sponsor",
      sponsorDetails: "http://sksl.co.in/",
      sponsorImage: "./2022/sksamanta.jpeg",
    },
    {
      sponsorName: "Fosroc",
      subTitle: "Platinum Sponsor",
      sponsorDetails: "https://www.fosroc.com/",
      sponsorImage: "./2022/fosroc.png",
    },
    {
      sponsorName: "Enkebee",
      subTitle: "Events Sponsor",
      sponsorDetails: "http://enkebee.com/",
      sponsorImage: "./2022/enkebee.jpeg",
    },
    {
      sponsorName: "Campus Times Pune",
      subTitle: "Youth Partner",
      sponsorDetails: "https://www.campustimespune.com/",
      sponsorImage: "./2022/campustimespune.jpeg",
    },
    {
      sponsorName: "The Education Tree",
      subTitle: "Youth Partner",
      sponsorDetails: "https://www.theeducationtree.com/",
      sponsorImage: "./2022/theeducationtree.jpeg",
    },
    {
      sponsorName: "Youth Incorporated",
      subTitle: "Youth Partner",
      sponsorDetails: "https://youthincmag.com/",
      sponsorImage: "./2022/youthincorporated.jpeg",
    },
    {
      sponsorName: "Campus Memories",
      subTitle: "Merchandise Partner",
      sponsorDetails: "https://campusmemories.co.in/",
      sponsorImage: "./2022/campusmemories.jpeg",
    },
    {
      sponsorName: "The Statesman",
      subTitle: "Newspaper Partner",
      sponsorDetails: "https://www.thestatesman.com/",
      sponsorImage: "./2022/thestatesman.jpeg",
    },
    {
      sponsorName: "India Blooms",
      subTitle: "Newspaper Partner",
      sponsorDetails: "https://www.indiablooms.com/",
      sponsorImage: "./2022/indiablooms.jpg",
    },
    {
      sponsorName: "Aapka Times",
      subTitle: "Newspaper Partner",
      sponsorDetails: "https://www.aapkatimes.com/",
      sponsorImage: "./2022/aapkatimes.jpeg",
    },
    {
      sponsorName: "India College Fest",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://indiacollegefest.com/",
      sponsorImage: "./2022/indiacollegefest.jpeg",
    },
    {
      sponsorName: "Know A Fest",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://www.knowafest.com/explore/events",
      sponsorImage: "./2022/knowafest.jpg",
    },
    {
      sponsorName: "Ohcampus",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://ohcampus.com/",
      sponsorImage: "./2022/ohcampus.jpeg",
    },
    {
      sponsorName: "MeraEvents",
      subTitle: "Ticketing Partner",
      sponsorDetails: "https://www.meraevents.com/",
      sponsorImage: "./2023/meraevents.jpeg",
    },
    {
      sponsorName: "Grab On",
      subTitle: "Coupon Partner",
      sponsorDetails: "https://www.grabon.in/",
      sponsorImage: "./2022/grabon.jpeg",
    },
    {
      sponsorName: "FriendsFM",
      subTitle: "Exclusive Radio Partner",
      sponsorDetails: "https://www.facebook.com/919FriendsFM/",
      sponsorImage: "./2022/friendsfm.jpeg",
    },
    {
      sponsorName: "Civil Shala",
      subTitle: "Training Partner",
      sponsorDetails: "https://civilshala.com/",
      sponsorImage: "./2022/civilshala.jpg",
    },
    {
      sponsorName: "Made Easy",
      subTitle: "Education Partner",
      sponsorDetails: "https://www.madeeasy.in/",
      sponsorImage: "./2022/madeeasy.jpeg",
    },
  ];

  const sponsorsContent2023 = [
    {
      sponsorName: "Elegant Steel",
      subTitle: "Title Sponsor",
      sponsorDetails: "https://www.elegantsteels.com/",
      sponsorImage: "./2023/elegant.png",
    },
    {
      sponsorName: "Tata Steel",
      subTitle: "Platinum Sponsor (Criar)",
      sponsorDetails: "https://www.fosroc.com/",
      sponsorImage: "./2023/tatasteel.png",
    },
    {
      sponsorName: "S. K. Samanta",
      subTitle: "Gold Sponsor",
      sponsorDetails: "http://sksl.co.in/",
      sponsorImage: "./2023/sksamanta.jpeg",
    },
    {
      sponsorName: "(SendFast) DCart Logistics",
      subTitle: "Event Sponsor",
      sponsorDetails: "https://www.sendfast.in/",
      sponsorImage: "./2023/dcart.png",
    },
    {
      sponsorName: "Enkebee",
      subTitle: "Event Sponsor",
      sponsorDetails: "http://enkebee.com/",
      sponsorImage: "./2023/enkebee.jpeg",
    },
    {
      sponsorName: "Aimil India",
      subTitle: "Major Sponsor",
      sponsorDetails: "https://www.aimil.com/",
      sponsorImage: "./2023/aimil.png",
    },
    {
      sponsorName: "Unichem",
      subTitle: "Major Sponsor",
      sponsorDetails: "https://www.uweco.co.in/",
      sponsorImage: "./2023/unichem.png",
    },
    {
      sponsorName: "Fosroc",
      subTitle: "Constructive Solutions Partner",
      sponsorDetails: "https://www.fosroc.com/",
      sponsorImage: "./2023/fosroc.jpg",
    },
    {
      sponsorName: "Civil Center",
      subTitle: "Workshop",
      sponsorDetails:
        "http://ww25.civilcenter.in/?subid1=20230226-0435-47dd-9e5c-da59a4e96f5a",
      sponsorImage: "./2023/civilcenter.png",
    },
    {
      sponsorName: "Henry Harvin",
      subTitle: "Events Partner",
      sponsorDetails: "https://www.henryharvin.com/",
      sponsorImage: "./2023/henryharwin.png",
    },
    {
      sponsorName: "Civil Shala",
      subTitle: "Events Partner",
      sponsorDetails: "https://civilshala.com/",
      sponsorImage: "./2023/civilshala.png",
    },
    {
      sponsorName: "Civil Guruji",
      subTitle: "Education Partner",
      sponsorDetails: "https://civilguruji.com/",
      sponsorImage: "./2023/civilguruji.png",
    },
    {
      sponsorName: "Gateforum",
      subTitle: "Education Partner",
      sponsorDetails: "https://gateforum.com/",
      sponsorImage: "./2023/gateforum.png",
    },
    {
      sponsorName: "Mera Events",
      subTitle: "Exclusive Ticket Partner",
      sponsorDetails: "https://www.meraevents.com/",
      sponsorImage: "./2023/meraevents.jpeg",
    },
    {
      sponsorName: "Youth Incorporated",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://youthincmag.com/",
      sponsorImage: "./2023/youthincorporated.jpeg",
    },
    {
      sponsorName: "College India Fest",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://indiacollegefest.com/",
      sponsorImage: "./2023/indiacollegefest.jpeg",
    },
    {
      sponsorName: "Silicon India",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://www.siliconindia.com/",
      sponsorImage: "./2023/siliconindia.png",
    },
    {
      sponsorName: "Campus Times Pune",
      subTitle: "Online Media Partner",
      sponsorDetails: "https://www.campustimespune.com/",
      sponsorImage: "./2023/campustimespune.jpeg",
    },
    {
      sponsorName: "Grab On",
      subTitle: "Coupon Partner",
      sponsorDetails: "https://www.grabon.in/",
      sponsorImage: "./2023/grabon.jpeg",
    },
    {
      sponsorName: "India Blooms",
      subTitle: "Newspaper Partner",
      sponsorDetails: "https://www.indiablooms.com/",
      sponsorImage: "./2023/indiablooms.jpg",
    },
  ];

  const sponsorsContent2024 = [
    {
      sponsorName: "Coming Soon",
    },
  ];

  const [show2022, setShow2022] = useState(false);
  const [show2023, setShow2023] = useState(false);
  const [show2024, setShow2024] = useState(true);
  const [showsponsors, setShowsponsors] = useState(false);

  return (
    <>
      <div id="sponsors">
        <Navbar />
        <h1 className="text-black text-6xl uppercase sponsors-div-title">
          Sponsors
        </h1>
        <div id="sponsors-backg"></div>
        <div id="sponsors-display">
          <div id="sponsors-buttons">
            <button
              onClick={() => {
                setShow2022(true);
                setShow2023(false);
                setShow2024(false);
              }}
              className="sponsors-btn"
            >
              2022
            </button>
            <button
              onClick={() => {
                setShow2022(false);
                setShow2023(true);
                setShow2024(false);
              }}
              className="sponsors-btn"
            >
              2023
            </button>
            <button
              onClick={() => {
                setShow2022(false);
                setShow2023(false);
                setShow2024(true);
              }}
              className="sponsors-btn"
            >
              2024
            </button>
          </div>
          <div id="sponsors-line"></div>

          <div id="sponsors-2022" className="sponsors-year-div">
            {show2022 && (
              <div>
                <h1 className="sponsors-year-title">Sponsors of 2022</h1>
                <div className="sponsors-cards-div">
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Title Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/sksamanta.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">S. K Samanta</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Title Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                S. K Samanta
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://sksl.co.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Platinum Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/fosroc.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Fosroc</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Platinum Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">Fosroc</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.fosroc.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Events Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/enkebee.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Enkebee</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">Enkebee</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://enkebee.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Youth Partners</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/campustimespune.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Campus Times Pune
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Youth Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Campus Times Pune
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.campustimespune.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/theeducationtree.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                The Education Tree
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Youth Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                The Education Tree
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.theeducationtree.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/youthincorporated.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Youth Incorporated
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Youth Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Youth Incorporated
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://youthincmag.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Merchandise Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/campusmemories.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Campus Memories
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Merchandise Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Campus Memories
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://campusmemories.co.in/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Newspaper Partners
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/thestatesman.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                The Statesman
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Newspaper Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                The Statesman
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.thestatesman.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/indiablooms.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">India Blooms</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Newspaper Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                India Blooms
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.indiablooms.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/aapkatimes.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Aapka Times</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Newspaper Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Aapka Times
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.aapkatimes.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Online Media Partners
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/indiacollegefest.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                India College Fest
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                India College Fest
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://indiacollegefest.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/knowafest.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Know A Fest</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Know A Fest
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.knowafest.com/explore/events">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/ohcampus.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title"> Ohcampus</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Ohcampus</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://ohcampus.com/ " target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Ticketing Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/meraevents.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">MeraEvents</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Ticketing Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                MeraEvents
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.meraevents.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Coupon Partner</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/grabon.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Grab On</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Coupon Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Grab On</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.grabon.in" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Exclusive Radio Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/friendsfm.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">FriendsFM</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Exclusive Radio Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">FriendsFM</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.facebook.com/919FriendsFM/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Training Partner</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/civilshala.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Civil Shala</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Training Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Civil Shala
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://civilshala.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Education Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/madeeasy.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Made Easy</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Education Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Made Easy</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.madeeasy.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div id="sponsors-2023" className="sponsors-year-div">
            {show2023 && (
              <div>
                <h1 className="sponsors-year-title">Sponsors of 2023</h1>
                <div className="sponsors-cards-div">
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Title Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/elegant.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Elegant Steel
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Title Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Elegant Steel
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.elegantsteels.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Platinum Sponsor
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/tatasteel.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Tata Steel</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Platinum Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Tata Steel
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.tatasteel.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Gold Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/sksamanta.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                S. K. Samanta
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Gold Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                S. K. Samanta
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://sksl.co.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Events Sponsors</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2022/enkebee.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Enkebee</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">Enkebee</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://enkebee.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/dcart.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                (SendFast) DCart Logistics
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                (SendFast) DCart Logistics
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.sendfast.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Major Sponsors</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/aimil.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Aimil India</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Aimil India
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.aimil.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/unichem.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Unichem</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">Unichem</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.uweco.co.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Constructive Solutions Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/fosroc.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Fosroc</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Constructive Solutions Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Fosroc</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.fosroc.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Workshop</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/civilcenter.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Civil Center</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">Workshop</h1>
                              <h1 className="sponsor-card-subtitle">
                                Civil Center
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://ww25.civilcenter.in/?subid1=20230226-0435-47dd-9e5c-da59a4e96f5a">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Events Partners</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/henryharwin.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Henry Harvin</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Henry Harvin
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.henryharvin.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/civilshala.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Civil Shala</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Civil Shala
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://civilshala.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Education Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/civilguruji.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Civil Guruji</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Education Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Civil Guruji
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://civilguruji.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/gateforum.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Gateforum</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Education Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Gateforum</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://gateforum.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Exclusive Ticket Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/meraevents.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Mera Events</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Exclusive Ticket Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Mera Events
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.meraevents.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Online Media Partners
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/youthincorporated.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Youth Incorporated
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Youth Incorporated
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://youthincmag.com/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/indiacollegefest.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                India College  Fest
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                College India Fest
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://indiacollegefest.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/siliconindia.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Silicon India
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Silicon India
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.siliconindia.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/campustimespune.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Campus Times Pune
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Campus Times Pune
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.campustimespune.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Coupon Partner</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/grabon.jpeg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">Grab On</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Coupon Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">Grab On</h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.grabon.in/" target="_blank" rel="noopener noreferrer" >Know More</a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">
                      Newspaper Partner
                    </h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/indiablooms.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">India Blooms</h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Newspaper Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                India Blooms
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.indiablooms.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div id="sponsors-2024" className="sponsors-year-div">
            {show2024 && (
              <div>
                <h1 className="sponsors-year-title">Sponsors of 2024</h1>
                <div className="sponsors-cards-div">
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Title Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/lnt.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Larsen And Toubro
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Title Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Larsen And Toubro
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a
                                  href="https://www.larsentoubro.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Co-Title Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2023/elegant.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Elegant Steel
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Co-Title Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Elegant Steel
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.elegantsteels.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Platinum Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/airark.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                AirArk
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Platinum Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                AirArk
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.airark.in/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Major Sponsors</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/rashmi.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Rashmi Group
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Rashmi Group
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.rashmigroup.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/haldia_petrochemicals_ltd_logo.jpeg`)}
                                className="sponsor-card-front-imgg"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Haldia Petrochemicals
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Haldia Petrochemicals
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.haldiapetrochemicals.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/jaibalajigroup.png`)}
                                className="sponsor-card-front-imgg"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Jai Balaji Group
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Jai Balaji Group
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://jaibalajigroup.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/aimil.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Aimil
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Major Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Aimil
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.aimil.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Events Sponsor</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/enkebee.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Enkebee
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Events Sponsor
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Enkebee
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="http://enkebee.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Workshop Partner</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/protrainy.jpeg`)}
                                className="sponsor-card-front-imgg"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Protrainy
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Workshop Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Protrainy
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.protrainy.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Merchandise Partner</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/custkart.png`)}
                                className="sponsor-card-front-imgg"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                CustKart
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Merchandise Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                CustKart
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.instagram.com/hicustkart/?hl=en">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Education Partner</h1>
                    <div className="sponsor-card-section-row">
                    <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/civil_guru_ji_logo.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Civil Guruji
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Civil Guruji
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://civilguruji.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/civilengineeringtc.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Civil Engineering TC
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Education Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                              Civil Engineering TC
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.facebook.com/civilengineering.tc/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Online Media Partner</h1>
                    <div className="sponsor-card-section-row">
                    <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/indiacollegefest.png`)}
                                className="sponsor-card-front-imgg"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                India College Fest
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                India College Fest
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.facebook.com/IndiaCollegeFest/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/campustimespune.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Campus Times Pune
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Campus Times Pune
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.campustimespune.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/youthincorporated.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Youth Incorporated
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Online Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Youth Incorporated
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://youthincmag.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>
                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Publicity Partner</h1>
                    <div className="sponsor-card-section-row">
                    <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/aboutce.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                About Civil Engineering
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Publicity Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                About Civil Engineering
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.instagram.com/about_civilengineers?igsh=MXR5OTVrZXh0b3Fxcg==">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sponsors-cards-row">
                    <h1 className="sponsors-cards-row-title">Media Partners</h1>
                    <div className="sponsor-card-section-row">
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/knowafest.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Know A Fest
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Know A Fest
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.knowafest.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/sachkahoon.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Sach Kahoon
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Sach Kahoon
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://epaper.sachkahoon.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/sachishiksha.jpg`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Sachi Shiksha
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Sachi Shiksha
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://sachishiksha.com/">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sponsor-card-section">
                        <div className="sponsor-card">
                          <div className="sponsor-card-front">
                            <div className="sponsor-card-front-body">
                              <img
                                src={require(`./2024/edugraph.png`)}
                                className="sponsor-card-front-img"
                                alt=""
                              ></img>
                              <div className="sponsor-card-line"></div>
                              <h1 className="sponsor-card-title">
                                Edugraph
                              </h1>
                            </div>
                          </div>
                          <div className="sponsor-card-back">
                            <div className="sponsor-card-back-body">
                              <h1 className="sponsor-card-subtitle">
                                Media Partner
                              </h1>
                              <h1 className="sponsor-card-subtitle">
                                Edugraph
                              </h1>
                              <div className="sponsor-card-line"></div>
                              <button className="sponsors-km">
                                <a href="https://www.telegraphindia.com/edugraph">
                                  Know More
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="sponsors-cards-div">
              {sponsorsContent2022.map((sponsor, index) => {
                return (
                  <>
                  <h1>{sponsor.subTitle}</h1>
                  <div className="sponsor-card">
                    <div className="sponsor-card-front">
                      <div className="sponsor-card-front-body">
                        <img
                          src={require(`${sponsor.sponsorImage}`)}
                          className="sponsor-card-front-img"
                        ></img>
                        <div className="sponsor-card-line"></div>
                        <h1 className="sponsor-card-title">
                          {sponsor.sponsorName}
                        </h1>
                      </div>
                    </div>
                    <div className="sponsor-card-back">
                      <div className="sponsor-card-back-body">
                        <h1 className="sponsor-card-subtitle">{sponsor.subTitle}</h1>
                        <div className="sponsor-card-line"></div>
                        <button className="sponsors-km">
                          <atarget="_blank" rel="noopener noreferrer" >Know More</a>
                        </button>
                      </div>
                    </div>
                  </div>
                  </>
                );
              })}
              </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsors;
