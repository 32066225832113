// import React,{useState,useEffect} from 'react'
// import { Country, State, City }  from 'country-state-city';
// import './Registration.css'
// import Selector from './Selector';
// console.log(Country.getAllCountries())
// console.log(State.getAllStates())
// const Registration = () => {
// 	const [isSignUpMode, setIsSignUpMode] = useState(false);

// 	const handleSignUpClick = () => {
// 		setIsSignUpMode(true);
// 	  };
	
// 	  const handleSignInClick = () => {
// 		setIsSignUpMode(false);
// 	  };
	
// 	let countryData = Country.getAllCountries();
//   const [stateData, setStateData] = useState();
//   const [cityData, setCityData] = useState();

//   const [country, setCountry] = useState(countryData[100]);
//   const [state, setState] = useState();
//   const [city, setCity] = useState();

//   useEffect(() => {
//     setStateData(State.getStatesOfCountry(country?.isoCode));
//   }, [country]);

//   useEffect(() => {
//     setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
//   }, [state]);

//   useEffect(() => {
//     stateData && setState(stateData[0]);
//   }, [stateData]);

//   useEffect(() => {
//     cityData && setCity(cityData[0]);
//   }, [cityData]);


//   return (
//     <div className='body'>
//     <div className="registration  " >
// 		<div className="registration-container">
// 			<div className="full-height justify-content-center">
// 				<div className="flex justify-center items-center h-screen col-12 text-center align-self-center py-5">
// 					<div className="registration-section pb-5 pt-5 pt-sm-2 text-center">
// 						<h6 className="mb-0 pb-3 text-white "><span className='bg-black py-2 mx-5 rounded-xl border-4 border-orange-400'>Log In </span><span className='mx-5 bg-black py-2 rounded-xl border-4 border-orange-400'>Sign Up</span></h6>
// 			          	<input className="registration-checkbox" type="checkbox" id="reg-log" name="reg-log"/>
// 			          	<label for="reg-log"></label>
// 						<div className="registration-card-3d-wrap mx-auto">
// 							<div className="registration-card-3d-wrapper">
// 								<div className="registration-card-front">
// 									<div className="registration-center-wrap">
// 										<div className="registration-section text-center">
// 											<h4 className="mb-4 pb-3">Log In</h4>
// 											<div className="registration-form-group">
// 												<input type="email" name="logemail" className="registration-form-style" placeholder="Your Email" id="logemail" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-at"></i>
// 											</div>	
// 											<div className="registration-form-group mt-2">
// 												<input type="password" name="logpass" className="registration-form-style" placeholder="Your Password" id="logpass" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-lock-alt"></i>
// 											</div>
// 											<a href="#" className="registration-btn mt-4">submit</a>
//                             				<p className="mb-0 mt-4 text-center"><a href="#0" className="link">Forgot your password?</a></p>
// 				      					</div>
// 			      					</div>
// 			      				</div>
// 								<div className="registration-card-back">
// 									<div className="registration-center-wrap">
// 											<h4 className="mb-4 pb-3">Sign Up</h4>
// 										<div className="registration-section text-center">
// 											<div className='scroll'>

// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Your Full Name" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>	
// 											<div className="registration-form-group mt-2">
// 												<input type="email" name="logemail" className="registration-form-style" placeholder="Your Email" id="logemail" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-at"></i>
// 											</div>	
// 											<div className="registration-form-group mt-2">
// 												<input type="password" name="logpass" className="registration-form-style" placeholder="Your Password" id="logpass" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-lock-alt"></i>
// 											</div>
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Confrim Password" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Phone Number" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="College" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
											
											
// 											{/* <div className=' registration-form-group registration-form-style' >

												
// 												<p className="text-black font-semibold z-50 ">

// 												<Selector
// 												data={countryData}
// 												selected={country}
// 												setSelected={setCountry}
// 												/>
// 												</p>
// 											</div >
// 											{state && (
// 												<div className=' registration-form-group registration-form-style '>

// 												<p className="text-black font-semibold z-40">
// 												<Selector
// 													data={stateData}
// 													selected={state}
// 													setSelected={setState}
// 												/>
// 												</p>
// 												</div>
// 											)}
// 											{city && (
// 												<div className=' registration-form-group registration-form-style '>
// 												<p className="text-black font-semibold z-30">

// 												<Selector data={cityData} selected={city} setSelected={setCity} />
// 												</p>
// 												</div>
// 											)} */}
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Gender" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Specify your years of study" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
// 											<div className="registration-form-group">
// 												<input type="text" name="logname" className="registration-form-style" placeholder="Were you a part of Megalith before?" id="logname" autocomplete="off"/>
// 												<i className="registration-input-icon uil uil-user"></i>
// 											</div>
// 											</div>
// 				      					</div>
// 											<a href="#" className="registration-btn mt-4">submit</a>
// 			      					</div>
// 			      				</div>
// 			      			</div>
// 			      		</div>
// 			      	</div>
// 		      	</div>
// 	      	</div>
// 	    </div>
// 	</div>
//     </div>
//   )
// }

// export default Registration


import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
import { Country, State, City }  from 'country-state-city';
import './Registration.css'
import Selector from './Selector';
import { Icon } from '@iconify/react';
import Navbar from '../Navbar/Navbar.js';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../Footer/Footer';
const Registration = () => {
	const [loading, setLoading] = useState(false);
	const [text,setText] = useState(false);
	const [selectedyear,setSelectedYear]=useState('')
	const handleYearChange=event=>{
		setSelectedYear(event.target.value)
	}
	const [selectedgender,setSelectedGender]=useState('')
	const handleGenderChange=event=>{
		setSelectedGender(event.target.value)
	}
	
	const navigate = useNavigate();
	const [selected,setSelected]=useState('');
	const handleChange = event => {
		setSelected(event.target.value);
	  };

	//Signin Methods
	const [signindata, setSigninData] = useState({ Email: "", Password: "" });
  	const [signinmsg, setSigninMsg] = useState("");
	const [signinerror,setSigninError]=useState("")

	const handleSigninChange = ({ currentTarget: input }) => {
		setSigninData({ ...signindata, [input.name]: input.value });
	};

	const handleSigninSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `${process.env.REACT_APP_BASE_URL}/signin`;
			const {data: res1} = await axios.post(url, signindata);
			localStorage.setItem("token", res1.data.token);
			localStorage.setItem("data", JSON.stringify(res1.data.user));
			setSigninMsg(res1.message);
			setSigninError(res1.error);
      		navigate(`/dashboard/${res1.data.user._id}`);
			window.location=`/dashboard/${res1.data.user._id}`
      		setSigninData({ Email: "", Password: "" });
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setSigninError(error.response.data.error);
			}
		}
	};

	//Signup Methods
	const [signupmsg, setSignupMsg] = useState("");
	const [signuperror,setSignupError]=useState("")
	const [data, setData] = useState({
		Name: "",
		Email: "",
		Password: "",
		ConfirmPassword: "",
	  	MobNumber: "",
		College: "",
		State: "",
	  	City: "",
		Gender: "",
		YearofStudy: "",
		MegalithID: ""
	  });
	  
	const handleSignupChange = ({ currentTarget: input }) => {
		  setData({ ...data, [input.name]: input.value });
	  };
  
	  const handleSignupSubmit = async (e) => {
		e.preventDefault();
		  try{
			  const url = `${process.env.REACT_APP_BASE_URL}/register`;
			  const { data: res2 } = await axios.post(url, data);
			  setSignupMsg(res2.message);
			  setSignupError(res2.error);
		setData({
		  Name: "",
		  Email: "",
		  Password: "",
		  ConfirmPassword: "",
		  MobNumber: "",
		  College: "",
		  State: "",
		  City: "",
		  Gender: "",
		  YearofStudy: "",
		  MegalithID: ""
		});
		
		  } catch (error) {
			  if (
				  error.response &&
				  error.response.status >= 400 &&
				  error.response.status <= 500
			  ) {
					setSignupError(error.response.data.message);
			  }
		  }
	  };

	const [isSignUpMode, setIsSignUpMode] = useState(false);

  useEffect(()=>{
	  notifysignin()
  },[signinmsg,signinerror])
  useEffect(()=>{
	  notifysignup()
	    },[signupmsg,signuperror])
  const notifysignin = () => 
  {
	if(signinmsg){
		toast.success(signinmsg);
	}
	if(signinerror){
		toast.error(signinerror);
	
	}
}
  const notifysignup = () => {
	if(signupmsg){
		toast.success(signupmsg);
	}
	if(signuperror){
		toast.error(signuperror);
	}
	
  }
  const handleClick = () =>{
	setText(!text)
	
  }
  return (
	<>
	<Navbar/>
    <div className='body'>
    <div className="registration" >
	<Toaster position="top-center" reverseOrder={false}/>
	<Toaster position="top-center" reverseOrder={false}/>
	<div className="registration-container py-5">
			<div className="full-height justify-content-center">
				<div className="flex justify-center items-center h-screen col-12 text-center align-self-center py-5">
					<div className="registration-section pb-5 pt-5 pt-sm-2 text-center">
						<h6 className="mb-0 pb-3 text-white registration-checkbox">
							<div className='show-toggle-card-3d-wrap mx-auto'>
								<div className='show-toggle-card-3d-wrapper'>
			
							<div className='show-toggle-front'>
								<div className='show-toggle-center-wrap'>
								<span className='bg-black  py-2 rounded-lg '>{text?"Already Registered? Login Now":'New Here? Register Now'}</span>
								</div>
							</div>
						
							</div>
							</div>
						</h6>
							<input onClick={handleClick} className="registration-checkbox" type="checkbox" id="reg-log" name="reg-log"/>
			          	<label for="reg-log"></label>
						<div className="registration-card-3d-wrap mx-auto">
							<div className="registration-card-3d-wrapper">
								<div className="registration-card-front">
									<div className="registration-center-wrap">
										<div className="registration-section text-center">
											<form onSubmit={handleSigninSubmit}>
											<h4 className="mb-4 pb-3 text-2xl">Log In</h4>
											<div className="registration-form-group">
												<input type="email" name="Email" className="registration-form-style" placeholder="Your Email" id="logemail" autocomplete="off" onChange={handleSigninChange} value={signindata.Email}/>
												<Icon icon="mdi:at" className="registration-input-icon" />
											</div>	
											<div className="registration-form-group mt-2">
												<input type="password" name="Password" className="registration-form-style" placeholder="Your Password" id="logpass" autocomplete="off" onChange={handleSigninChange} value={signindata.Password}/>
												<Icon icon="material-symbols:lock" className="registration-input-icon"/>
											</div>
											<input onClick={notifysignin} type="submit" className="registration-btn mt-4 btn" value="Submit"/>
                            				<p className="mb-0 mt-4 text-center"><Link to="/forgotpassword">Forgot your password?</Link></p>
											</form>
				      					</div>
			      					</div>
			      				</div>
								<div className="registration-card-back">
									<div className="registration-center-wrap">
									<form onSubmit={handleSignupSubmit}>
											<h4 className="mb-4 pb-3 text-2xl">Sign Up</h4>
										<div className="registration-section text-center">
											<div className='scroll'>
											<div className="registration-form-group">
												<input type="text" name="Name" className="registration-form-style" placeholder="Your Full Name" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.Name}/>
												<Icon icon="icon-park-outline:people" className="registration-input-icon"/>
											</div>	
											<div className="registration-form-group mt-2">
												<Icon icon="mdi:at" className="registration-input-icon" />
												<input type="email" name="Email" className="registration-form-style" placeholder="Your Email" id="logemail" autocomplete="off" onChange={handleSignupChange} value={data.Email}/>
											</div>	
											<div className="registration-form-group mt-2">
												<input type="password" name="Password" className="registration-form-style" placeholder="Your Password" id="logpass" autocomplete="off" onChange={handleSignupChange} value={data.Password}/>
												<Icon icon="material-symbols:lock" className="registration-input-icon"/>
											</div>
											<div className="registration-form-group">
												<input type="password" name="ConfirmPassword" className="registration-form-style" placeholder="Confirm Password" id="logpass" autocomplete="off" onChange={handleSignupChange} value={data.ConfirmPassword}/>
												<Icon icon="material-symbols:lock" className="registration-input-icon"/>
											</div>
											<div className="registration-form-group">
												<input type="text" name="MobNumber" className="registration-form-style" placeholder="Phone Number" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.MobNumber}/>
												<Icon icon="ic:baseline-phone" className="registration-input-icon"/>
											</div>
											<div className="registration-form-group">
												<input type="text" name="College" className="registration-form-style" placeholder="College" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.College}/>
												<Icon icon="mdi:college-outline" className="registration-input-icon"/>
											</div>
											<div className="registration-form-group">
												<input type="text" name="State" className="registration-form-style" placeholder="State" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.State}/>
												<Icon icon="mdi:location" className="registration-input-icon"/>
											</div>
											<div className="registration-form-group">
												<input type="text" name="City" className="registration-form-style" placeholder="City" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.City}/>
												<Icon icon="mdi:location" className="registration-input-icon"/>
											</div>
											{/* <div className=' registration-form-group registration-form-style' >

												
												<p className="text-black font-semibold z-50 ">

												<Selector
												data={countryData}
												selected={country}
												setSelected={setCountry}
												/>
												</p>
											</div >
											{state && (
												<div className=' registration-form-group registration-form-style '>

												<p className="text-black font-semibold z-40">
												<Selector
													data={stateData}
													selected={state}
													setSelected={setState}
												/>
												</p>
												</div>
											)}
											{city && (
												<div className=' registration-form-group registration-form-style '>
												<p className="text-black font-semibold z-30">

												<Selector data={cityData} selected={city} setSelected={setCity} />
												</p>
												</div>
											)} */}
											<div className="registration-form-group">
												<Icon icon="icons8:gender" className="registration-input-icon"/>
												<select type="text" name="Gender" className="registration-form-style" placeholder="Gender" id="logname"  onChange={handleSignupChange} value={data.Gender}>
												<option className="options-data">Gender</option>
												<option value="Male" setSelected="Male" className='' onChange={handleGenderChange}>Male</option>
												<option value="Female" setSelected="Female" className='' onChange={handleGenderChange}>Female</option>
												<option value="Prefer not to say" setSelected="Prefer not to say" className='' onChange={handleGenderChange}>Prefer not to say</option>
												</select>
											</div>
											<div className="registration-form-group">
												<Icon icon="fa6-solid:user-graduate" className="registration-input-icon"/>
												<select type="text" name="YearofStudy" className="registration-form-style" placeholder="Specify your years of study" id="logname" onChange={handleSignupChange} value={data.YearofStudy}>
												<option className="options-data">Specify Your Years of Study</option>
												<option value="1" setSelected="1" className='' onChange={handleYearChange}>1</option>
												<option value="2" setSelected="2" className='' onChange={handleYearChange}>2</option>
												<option value="3" setSelected="3" className='' onChange={handleYearChange}>3</option>
												<option value="4" setSelected="4" className='' onChange={handleYearChange}>4</option>
												<option value="5" setSelected="5" className='' onChange={handleYearChange}>5</option>
												</select>
											</div>
											<div className="registration-form-group">
												<label  name="ifPMegalith" className="registration-form-style-1"  id="logname">Referrenced By Megalith Campus Ambassador?</label>
											</div>
											<div className="options">
												<input type="radio" name="ifPMegalith" className="radio-buttons" id="Yes" onChange={handleChange} value="Yes"/>
												<label htmlFor="Yes" className='Yes'>Yes</label>
												<input type="radio" name="ifPMegalith" className="radio-buttons" id="No" onChange={handleChange} value="No" />
												<label htmlFor="No">No</label>
											</div>
											{(selected==="Yes")?(
												<div className="registration-form-group">
													<input type="text" name="MegalithID" className="registration-form-style" placeholder="Megalith ID" id="logname" autocomplete="off" onChange={handleSignupChange} value={data.MegalithID}/>
													<Icon icon="mdi:account-badge-horizontal-outline" className="registration-input-icon"/>
												</div>
												):(selected==="Yes")?(<div></div>):(<div></div>)}
											</div>
				      					</div>
											{!loading?
												<input type="submit" className="registration-btn mt-4 btn" value="Submit" />
												:<div className='col-12 center'>
            <button className='w-100 text-center px-4' type="submit">
              <div className="spinner-border" role="status">
                <span className="registration-btn mt-4">Loading...</span>
              </div>
            </button>
          </div>}
											{signupmsg && <div className='signupmsg'>{signupmsg}</div>}
									  </form>
			      					</div>
			      				</div>
			      			</div>
			      		</div>
			      	</div>
		      	</div>
	      	</div>
	    </div>
	</div>
    </div>
	<Footer/>
	</>
	
  )
}

export default Registration