import React from 'react'
import './EventsWorkshop.css'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar.js'
const EventsWorkshop = () => {
  return (
    <>
    <Navbar/>
    <div className='events-body'>
    <section className="cards-wrapper-EW">
        <a href="/events">
    <div className="card-grid-space">
    <div className=" workshop-card event6">
      <div>
        <h1 className='event-title-EW'>EVENTS</h1>
        
       
      </div>
    </div>
  </div>

        </a>
  <a href="/workshop">
  <div className="card-grid-space">
    <div className="workshop-card workshop">
      <div>
        <h1 className='event-title-EW'>WORKSHOP</h1>
      </div>
    </div>
  </div>
  </a>
</section>
    </div>
<Footer/>
    </>
  )
}

export default EventsWorkshop
