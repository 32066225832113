import React, { useRef,useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame,useThree } from "@react-three/fiber";
// import { a } from '@react-spring/three'

import spaceScene from "../../Assets/Images/3dModels/final-design8.glb"
import { BoxGeometry, SphereGeometry } from "three";

const Space = (props) => {
    const spaceRef = useRef();
   
    const { nodes, materials } = useGLTF(spaceScene);
    return (
      <group {...props} dispose={null}>
        <group
          position={[3.465, 5.293, 2.767]}
          rotation={[-Math.PI / 2, 0, -3.14]}
          scale={[5.999, 5.838, 1]}
        >
          <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0001.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0002.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0003.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0004.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0005.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0006.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.VC_Map_VC_Map_Upscaled_0007.geometry}
              material={materials.VC_Map_Upscaled}
              scale={[2.352, 1, 1.953]}
            />
          </group>
        </group>
        <group
          position={[-3.317, -10.716, -3.403]}
          rotation={[-1.472, 0.002, 0.022]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh01.geometry}
            material={materials.Color_A0}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh02.geometry}
            material={materials["Color_A0.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh03.geometry}
            material={materials["Color_A0.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh04.geometry}
            material={materials["Color_A0.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh05.geometry}
            material={materials["Color_A0.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh06.geometry}
            material={materials["Color_A0.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh07.geometry}
            material={materials["Color_A0.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh08.geometry}
            material={materials["Color_A0.007"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh09.geometry}
            material={materials["Color_A0.008"]}
            position={[-1.013, 0.129, 0.19]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh10.geometry}
            material={materials["Color_A0.009"]}
            position={[-0.001, -0.007, -0.81]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh11.geometry}
            material={materials["Color_A0.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh12.geometry}
            material={materials["Color_A0.011"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh13.geometry}
            material={materials["Color_A0.012"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh14.geometry}
            material={materials["Color_A0.013"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh15.geometry}
            material={materials["Color_A0.014"]}
            rotation={[0, 0, -0.014]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh16.geometry}
            material={materials["Color_A0.015"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh17.geometry}
            material={materials["Color_A0.016"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh18.geometry}
            material={materials["Color_A0.017"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh19.geometry}
            material={materials["Color_A0.018"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh20.geometry}
            material={materials["Color_A0.019"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh21.geometry}
            material={materials["Color_A0.020"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh22.geometry}
            material={materials["Color_A0.021"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh23.geometry}
            material={materials["Color_A0.022"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh24.geometry}
            material={materials["Color_A0.023"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh26.geometry}
            material={materials["Color_A0.025"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh27.geometry}
            material={materials["Color_A0.026"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh28.geometry}
            material={materials["Color_A0.027"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh29.geometry}
            material={materials["Color_A0.028"]}
            position={[-0.001, -0.007, -0.81]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh30.geometry}
            material={materials["Color_A0.029"]}
            position={[-0.001, -0.007, -0.81]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh31.geometry}
            material={materials.Foregrou}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh33.geometry}
            material={materials["Foregrou.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh34.geometry}
            material={materials["Foregrou.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh35.geometry}
            material={materials["Foregrou.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh36.geometry}
            material={materials["Foregrou.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh37.geometry}
            material={materials["Foregrou.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh38.geometry}
            material={materials["Foregrou.007"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh39.geometry}
            material={materials["Foregrou.008"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh40.geometry}
            material={materials["Foregrou.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh41.geometry}
            material={materials["Foregrou.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh42.geometry}
            material={materials["Foregrou.011"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh43.geometry}
            material={materials["Foregrou.012"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh25_1.geometry}
            material={materials["Color_A0.024"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh25_2.geometry}
            material={materials.FrontCol}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          material={materials["Material.002"]}
          position={[-1.922, -15.507, 2.21]}
          rotation={[0, 0.02, -0.001]}
          scale={[151.207, 165.368, 131.331]}
        />
      </group>
    );
};


export default Space;
