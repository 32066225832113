/* eslint-disable no-unused-vars */
import React from 'react'
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import FileSaver from 'file-saver';
import { Link, Link as LinkR } from 'react-router-dom';
import './HeroSection.css'
import PageContent from './Compoetitions'
import megalithlogo from '../../Assets/Images/Alum brochure  (1)-min.png'
import Navbar from '../Navbar/Navbar.js'
import banner from '../../Assets/Images/Untitled design.png'
import {motion} from 'framer-motion'
import { fadeIn } from '../../Assets/Images/Motions/variants'
import { FaArrowRight } from 'react-icons/fa'
import megalogo from '../../Assets/Images/Alum brochure  (1)-min.png'
import Footer from '../Footer/Footer'
import Image1 from '../../Assets/Images/Photogallery/1.JPG'
import Image2 from '../../Assets/Images/Photogallery/2.JPG'
import Image3 from '../../Assets/Images/Photogallery/3.JPG'
import Image4 from '../../Assets/Images/Photogallery/4.JPG'
import Image5 from '../../Assets/Images/Photogallery/5.JPG'
import Image6 from '../../Assets/Images/Photogallery/6.JPG'
import Image7 from '../../Assets/Images/Photogallery/7.JPG'
import Image8 from '../../Assets/Images/Photogallery/8.jpg'
import toast, { Toaster } from 'react-hot-toast';


const HeroSection = () => {
  
  
    var cards = [{ title: 'Mountain View',
                 copy: 'Check out all of these gorgeous mountain trips with beautiful views of, you guessed it, the mountains', 
                 button: 'View Trips' },
                 { title: 'To The Beach', 
                 copy: 'Plan your next beach trip with these fabulous destinations',
                 button: 'View Trips' }, 
                 { title: 'Desert Destinations', 
                 copy: 'It\'s the desert you\'ve always dreamed of', 
                 button: 'Book Now' }, 
                 { title: 'Explore The Galaxy', 
                 copy: 'Seriously, straight up, just blast off into outer space today', 
                 button: 'Book Now' }]
                 const [showNotifications, setShowNotifications] = useState(false)
                 const message=()=>{
                  toast("Coming Soon...",{duration:1000});}
                  const [pdfFile, setPdfFile] = useState(null);
                  const downloadPDF = () => {
                    // Get the PDF file from the server.
                    fetch('/')
                      .then(response => response.blob())
                      .then(blob => {
                        // Save the PDF file to the user's computer.
                        FileSaver.saveAs(blob, '#');
                      });
                  };
                
  return (
    <>
    <Navbar/>
    <Toaster position="top-center" reverseOrder={false}/>
    <div>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />


<script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js" />


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js" />


<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap" rel="stylesheet" />







<header className=" bg-black overflow-x-hidden">
    <article>
        <img className='megalith-banner w-full  h-screen overflow-x-hidden'/>
        <div className="notifications-hero">
      <div
        className="notification-bell flex items-center justify-center"
        onClick={() => {
          if (showNotifications) {
            setShowNotifications(false);
          } else {
            setShowNotifications(true);
          }
        }}
      >
        <li className='number-bell'>1</li>
        <i class="fa fa-bell-o bell-icon" aria-hidden="true"></i>
      {showNotifications && <div className="notifications-div text-black">
        <ul className="bullet-notification">
        <li className='flex p-1'><b>1)  The fest concluded from March 1st to 3rd. Looking forward to seeing you again next year!</b></li>
        </ul>
        </div>}
      </div>
    </div>
        <div className='hero-btns'>
      <Link to='/registration' type='button' className='hero-register-btn'>
        <span className='px-2'> REGISTER NOW </span>
      </Link>
      <Link download={{}} type='button' className='hero-schedule-btn' onClick={message}>
        <span className='px-2'>SCHEDULE</span>
      </Link>
        </div>
    </article>
</header>

{ /*MAIN*/ }
<form className='background-img overflow-x-hidden '>
<main>
  <motion.div className='AboutUs flex flex-col items-center p-8'
  variants={fadeIn('right', 0.3)}
  initial='hidden'
  whileInView={'show'}
  viewport={{ once: false, amount: 0.3 }}
  >
        <h2 className='uppercase text-4xl flex font-bold justify-center text-white font-[Afacad]'>About Us</h2>
        <motion.div className='AboutUs-box flex items-center justify-center flex-col'
        variants={fadeIn('down', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        >
          <img src={megalogo} alt="" className='megalogo rounded-sm' />
          <div className='m-8 text-2xl flex justify-center items-center flex-col'>
          <motion.p className='matter text-lg m-2 flex justify-center items-center text-justify uppercase '
          variants={fadeIn('down', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          >MEGALITH, the annual technical fest of the Department of Civil Engineering at the Indian Institute of Technology, Kharagpur, held under the aegis of the Civil Engineering Society, IIT Kharagpur, and patronized by the Institution of Civil Engineers (UK), IIT Kharagpur Chapter, has achieved tremendous success since its 2009 inception. It stands as India's largest civil engineering technical fest, offering diverse competitions, workshops, and guest lectures. Campus Ambassadors facilitate communication and promotion, providing rewarding personal and career development opportunities.
          </motion.p>
          {/* <motion.p className='matter text-lg m-2 flex justify-center items-center text-justify '
          variants={fadeIn('down', 0.6)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          >CAMPUS AMBASSADORS SERVE AS A CONNECTION BETWEEN THEIR COLLEGE AND OUR CAMPUS I.E. AS A NODAL POINT FOR ANY KIND OF COMMUNICATION OR PROMOTION. THIS IS A DEEPLY REWARDING OPPORTUNITY BOTH ON A PERSONAL LEVEL AND ALSO IN TERMS OF CAREER PROSPECTS AS, BEING A PART OF CAP HELPS IN PERSONALITY DEVELOPMENT BESIDES, ENHANCING YOUR MANAGERIAL SKILLS.</motion.p> */}
          </div>
        </motion.div>
  </motion.div>
</main>
<main>
    { /*DIVISION_1*/ }
    <motion.div className="divisions division_1"
     variants={fadeIn('left', 0.3)}
     initial='hidden'
     whileInView={'show'}
     viewport={{ once: false, amount: 0.3 }}
    >
        <h2 className='pb-8 text-4xl font-bold flex justify-center text-white font-[Afacad]'>THEME REVEAL</h2>
        <motion.div className='flex justify-center '
         variants={fadeIn('down', 0.3)}
         initial='hidden'
         whileInView={'show'}
         viewport={{ once: false, amount: 0.3 }}
        >
        <embed src='https://www.youtube.com/embed/_yBsIiTKhNE?si=VZULP7cz3qAmYEA0' className='yt'></embed>
        </motion.div>
    </motion.div>
</main>

<main>
    { /*DIVISION_2*/ }
    <motion.div className="divisions division_2"
    variants={fadeIn('right', 0.3)}
    initial='hidden'
    whileInView={'show'}
    viewport={{ once: false, amount: 0.3 }}
    >
      <div className='flex flex-col justify-center'>

        <h2 className='flex pt-8 pl-8 text-4xl font-bold justify-center text-white font-[Afacad]'>EVENTS</h2>
        <PageContent />
      </div>
            
        <motion.li 
        variants={fadeIn('left', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        className='flex justify-end pt-4 pb-10 '>
        <a href="/events&workshop" className='flex flex-row px-2 justify-between items-center border-2 rounded-md bg-white border-green-800 text-green-800 hover:text-white hover:bg-green-800 hover:border-white'>
                view more<FaArrowRight/>
        </a>
        </motion.li>
    </motion.div>
</main>    
    
    { /*DIVISION_3*/ }
    {/* <div className="divisions division_3"> */}
    <main>

    <motion.div className="herocontainer"
    variants={fadeIn('left', 0)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
    >
  <h1 className='gallery-heading font-bold flex justify-center font-[Afacad]'>GALLERY</h1>
  <motion.div className="gallery"
  variants={fadeIn('left', 0)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
  >
    <motion.figure className='photo-gallery'
    variants={fadeIn('left', 0.1)}
    initial="hidden"
    whileInView={'show'}
    viewport={{once: false,amount:0.3}}>
      <img src={Image1} alt="unsplash image" />
    </motion.figure>
     <motion.figure className='photo-gallery'
variants={fadeIn('left', 0.3)}
             initial="hidden"
             whileInView={'show'}
             viewport={{once: false,amount:0.3}}>
      <img src={Image2} alt="unsplash image" />
    </motion.figure>
     <motion.figure className='photo-gallery'
variants={fadeIn('left', 0.5)}
             initial="hidden"
             whileInView={'show'}
             viewport={{once: false,amount:0.3}}>
      <img src={Image3} alt="unsplash image" />
    </motion.figure>
    <motion.figure className='photo-gallery'
variants={fadeIn('left', 0.7)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false,amount:0.3}}>
      <img src={Image4} alt="unsplash image" />
    </motion.figure>
  </motion.div>


    <motion.div className='media-gallery'
    variants={fadeIn('right', 0)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
    >
       <motion.figure className='photo-gallery'
    variants={fadeIn('right', 1.5)}
    initial="hidden"
    whileInView={'show'}
    viewport={{once: false,amount:0.3}}>
      <img src={Image5} alt="unsplash image" />
    </motion.figure>
     <motion.figure className='photo-gallery'
variants={fadeIn('right', 1.3)}
             initial="hidden"
             whileInView={'show'}
             viewport={{once: false,amount:0.3}}>
      <img src={Image6} alt="unsplash image" />
    </motion.figure>
     <motion.figure className='photo-gallery'
variants={fadeIn('right', 1.1)}
             initial="hidden"
             whileInView={'show'}
             viewport={{once: false,amount:0.3}}>
      <img src={Image7} alt="unsplash image" />
    </motion.figure>
    <motion.figure className='photo-gallery'
variants={fadeIn('right', 0.9)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false,amount:0.3}}>
      <img src={Image8} alt="unsplash image" />
    </motion.figure>
    </motion.div>
    
</motion.div>
    </main>
    <main>
    { /*DIVISION_1*/ }
    <motion.div className="divisions division_1"
     variants={fadeIn('left', 0.3)}
     initial='hidden'
     whileInView={'show'}
     viewport={{ once: false, amount: 0.3 }}
    >
        <h2 className='pb-8 text-4xl font-bold flex justify-center text-white font-[Afacad]'>AFTER MOVIE</h2>
        <motion.div className='flex justify-center '
         variants={fadeIn('down', 0.3)}
         initial='hidden'
         whileInView={'show'}
         viewport={{ once: false, amount: 0.3 }}
        >
        <embed src='https://www.youtube.com/embed/8171ynPfDjM?si=mUQAocSDUO0qAV57' className='yt'></embed>
        </motion.div>
    </motion.div>
</main>
<Footer/>
    {/* </div> */}


{ /*FOOTER*/ }

</form>


{ /*ADDITIONAL*/ }


{ /*JAVASCRIPT
 <script type="text/javascript" src="assets/js/main.js"></script>
 <script type="text/javascript" src="assets/js/animate.js"></script>
 <script type="text/javascript" src="assets/js/home.js"></script>
 */ }


    </div>
    </>
  )
}

export default HeroSection;
