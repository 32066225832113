import React, { useState, useEffect, useRef } from 'react'
import "./BeatDEuclid.css"
import Navbar from '../Navbar/Navbar.js';
import  WAVES from 'vanta/dist/vanta.waves.min'
import Footer from '../Footer/Footer';
const ModelExhibition = (props) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x96828,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <>
      <Navbar/>
    <div className='eventsinfo' >
    <div className='eventsinfo-body' ref={myRef}>
    
      <div className='py-20'>

      <div className='event-name'>Model Exhibition</div>
    <div className="cs-container" >
      <div className='left-side'>
      <div className="information-container">
        <h1 className="information-title">Information</h1>
        <p className="information-content">
         <i>Green buildings represent a transformative approach to sustainable architecture, integrating innovative design and eco-friendly technologies to minimise environmental impact. These structures prioritise energy efficiency through features such as advanced insulation, high-performance windows, and energy-efficient lighting systems. The incorporation of renewable energy sources, such as solar panels and wind turbines, further reduces reliance on conventional power grids. Additionally, green buildings often utilise recycled or locally sourced materials, promoting resource conservation and supporting local economies. Beyond energy considerations, green buildings incorporate water-saving technologies, efficient waste management systems, and green roofs that enhance biodiversity and mitigate urban heat island effects. Overall, green buildings exemplify a holistic commitment to environmental responsibility, providing a blueprint for constructing spaces that harmonise with nature while offering sustainable and healthy living environments for inhabitants.</i>
        </p>
      </div>
   
      <div className="problem-container">
      <h1 className="problem-title ">Problem Statement</h1>
        <p className="problem-content">
         <i>One exemplary green building that has garnered international recognition is The Edge, located in Amsterdam, Netherlands. Completed in 2015, this cutting-edge office building is not only architecturally striking but also sets a high standard for sustainability. The Edge incorporates a range of green building features, including an energy-efficient design, rooftop solar panels that generate electricity, rainwater harvesting systems, and advanced climate control systems. The building also maximises natural light and ventilation, reducing the need for artificial lighting and air conditioning. Additionally, The Edge employs smart building technologies to optimise energy consumption, utilising sensors and data analytics to create an environmentally conscious and energy-efficient workspace. Its innovative approach to sustainability has earned The Edge the title of "the greenest building in the world" and serves as a compelling example of how sustainable design principles can be successfully integrated into modern architectural practices.
          <br/> <b className='flex text-center w-full items-center justify-center'>TASK</b> <br/>
 
 Participants should design and construct a model of a net-zero energy green building complex. Emphasise the incorporation of sustainable architectural and civil engineering principles to create a cutting-edge environmentally friendly structure. Participants should explore energy-efficient building materials, passive design strategies, and the integration of renewable energy sources such as solar panels and wind turbines. The challenge could also include water-saving technologies, green roofs, and smart building systems for efficient resource utilisation. Participants needs to showcase how the complex not only minimises its environmental footprint but also contributes positively to the surrounding community, fostering a sense of sustainability and innovation in urban development. They may include rainwater harvesting techniques etc. 
  
 Your model should include green roofs, solar panels, and energy-efficient windows. You may include rainwater harvesting or any other techniques to enhance the overall sustainability theme. Proper landscape should be made. You might include green spaces and native plants around the environment. 
 
 The model includes two main things
 
  The building structure 
 The nature and surrounding, landscape details <br/>
 
 Note:- <br/>
 1.The dimensions of model is length-1m,breadth-1m,height-60cm.<br/>
 2. Make relevant assumptions, if required.<br/>
 
 3. Participants are required to submit a report which includes the design of the model and its description.<br/>
 4. The report must not exceed 15 pages.
 <br/>
</i>
        </p>
      </div>
      
    
      </div>
      <div className='right-side'>
      <div className="rules-container">
        <h1 className="rules-title">Rules</h1>
        <p className="rules-content">
         <i>1. The event is open to all. <br/>
2. The team must consist of a minimum of 2 members and a maximum of 5 members.<br/>
 3. The model should be handmade with household and stationary tools. <br/>
4. Readymade and factory models are not acceptable. <br/>
5. Each group should present the list of materials used for making the model<br/>
6. Teams have to present the detailed idea behind all features of the model within 10 minutes and there will be a query session of 5 minutes at the end of the presentation.<br/>
7. The decision of judges shall be final and binding<br/>

<br/>
<b>Judging criteria:</b>   <br/>
Report: - 10%  <br/>
Economic feasibility: - 15%  <br/>
QA: - 15%  <br/>
Detailing: - 15%  <br/>
Presentation: - 15%  <br/>
Innovation: - 30% <br/>

</i>
        </p>
      </div>
      <div className="prize-container">
        <h1 className="prize-title">Prize</h1>
        <p className="prize-content">
         <i>1st-8000/- <br/>
         2nd-5000/- <br/>
         3rd-3000/-</i>
        </p>
      </div> 
      </div>
    </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
}

export default ModelExhibition