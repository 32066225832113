// import React, { Suspense } from 'react'
// import HeroSection from './Components/HeroSection/HeroSection'
// import Mainpage from './Components/MainPage/Mainpage'
// import {
//   BrowserRouter,
//   Route,
//   Routes,
//   Navigate,
//   useParams
// } from "react-router-dom";
// import GreenCanvas from './Components/Events/GreenCanvas'
// import Photography from './Components/Events/Photography'
// import BeatDEuclid from './Components/Events/BeatDEuclid'
// import Criar from './Components/Events/Criar'
// import Cwiz from './Components/Events/Cwiz'
// import ModelExhibition from './Components/Events/ModelExhibition'
// import Darkode  from './Components/Events/Darkode'
// import Rotolare from './Components/Events/Rotolare'
// import Ourteam from './Components/Ourteam/Ourteam';
// import Registration from './Components/Registration/Registration';
// import Events from './Components/Events/Events';
// import Sponsors from './Components/Sponsors/Sponsors';
// import Loader from './Components/MainPage/Loader';
// import Workshop from './Components/Workshop/Workshop';
// import EventsWorkshop from './Components/Events_Workshop/EventsWorkshop';
// import Dashboard1 from './Components/Registration/Dashboard/Dashboard1';
// import PasswordReset from './Components/Registration/PasswordReset/PasswordReset';
// import EmailVerify from './Components/Registration/EmailVerify/EmailVerify'
// import ForgotPassword from './Components/Registration/ForgotPassword/ForgotPassword';
// const Home = () => {
//   const user=localStorage.getItem("token")
//   return (
//       <Suspense fallback={<Loader/>} >
//     <BrowserRouter>
//     <Routes>

//       <Route path="/" element={<Mainpage/>} exact/>
//       <Route path="/Home" element={<HeroSection/>} />
//       <Route path="/events" element={<Events/>} />
//       <Route path="/events/greenCanvas" element={<GreenCanvas/>} />
//       <Route path="/events/photography" element={<Photography/>} />
//       <Route path="/events/cwiz" element={<Cwiz/>} />
//       <Route path="/events/beatDEuclid" element={<BeatDEuclid/>} />
//       <Route path="/events/darkode" element={<Darkode/>} />
//       <Route path="/events/modelExhibition" element={<ModelExhibition/>} />
//       <Route path="/events/criar" element={<Criar/>} />
//       <Route path="/events/rotolare" element={<Rotolare/>} />
//       <Route path="/sponsors" element={<Sponsors/>} />
//       <Route path="/workshop" element={<Workshop/>} />
//       <Route path="/events&workshop" element={<EventsWorkshop/>} />
//       <Route path="/ourteam" element={<Ourteam/>} />
//       <Route path="/registration" element={<Registration/>} />
//       <Route path="/registration/forgotpassword" element={<ForgotPassword/>} />
//       <Route path="/password-reset/:id/:token" element={<PasswordReset/>}/>
//       <Route path="/users/:id/verify/:token" element={<EmailVerify/>} exact/>
//       {user ? <Route path="/dashboard/:id" element={<Dashboard1/>}/> : <Route path="/dashboard/:id" element={<Navigate replace to="/registration" />} exact/>}

//     </Routes>

//   </BrowserRouter>
//       </Suspense>
//   )
// }

// export default Home;


import React, { Suspense } from 'react'
import HeroSection from './Components/HeroSection/HeroSection'
import Mainpage from './Components/MainPage/Mainpage'
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams
} from "react-router-dom";
import GreenCanvas from './Components/Events/GreenCanvas'
import Photography from './Components/Events/Photography'
import BeatDEuclid from './Components/Events/BeatDEuclid'
import Criar from './Components/Events/Criar'
import Cwiz from './Components/Events/Cwiz'
import ModelExhibition from './Components/Events/ModelExhibition'
import Darkode  from './Components/Events/Darkode'
import Rotolare from './Components/Events/Rotolare'
import Ourteam from './Components/Ourteam/Ourteam';
import Registration from './Components/Registration/Registration';
import Events from './Components/Events/Events';
import Sponsors from './Components/Sponsors/Sponsors';
import Loader from './Components/MainPage/Loader';
import Workshop from './Components/Workshop/Workshop';
import EventsWorkshop from './Components/Events_Workshop/EventsWorkshop';
import Dashboard1 from './Components/Registration/Dashboard/Dashboard1';
import PasswordReset from './Components/Registration/PasswordReset/PasswordReset';
import EmailVerify from './Components/Registration/EmailVerify/EmailVerify'
import ForgotPassword from './Components/Registration/ForgotPassword/ForgotPassword';
import Debate from './Components/Events/Debate';
import Instridge from './Components/Events/Instridge';
const Home = () => {
  const user=localStorage.getItem("token")
  return (
      <Suspense fallback={<Loader/>} >
    <BrowserRouter>
    <Routes>

      <Route path="/" element={<Mainpage/>} exact/>
      <Route path="/Home" element={<HeroSection/>} />
      <Route path="/events" element={<Events/>} />
      <Route path="/events/greenCanvas" element={<GreenCanvas/>} />
      <Route path="/events/photography" element={<Photography/>} />
      <Route path="/events/cwiz" element={<Cwiz/>} />
      <Route path="/events/beatDEuclid" element={<BeatDEuclid/>} />
      <Route path="/events/darkode" element={<Darkode/>} />
      <Route path="/events/modelExhibition" element={<ModelExhibition/>} />
      <Route path="/events/criar" element={<Criar/>} />
      <Route path="/events/debate" element={<Debate/>} />
      <Route path="/events/instridge" element={<Instridge/>} />
      <Route path="/events/rotolare" element={<Rotolare/>} />
      <Route path="/sponsors" element={<Sponsors/>} />
      <Route path="/workshop" element={<Workshop/>} />
      <Route path="/events&workshop" element={<EventsWorkshop/>} />
      <Route path="/ourteam" element={<Ourteam/>} />
      <Route path="/registration" element={<Registration/>} />
      <Route path="/forgotpassword" element={<ForgotPassword/>} />
      <Route path="/password-reset/:id/:token" element={<PasswordReset/>}/>
      <Route path="/users/:id/verify/:token" element={<EmailVerify/>} exact/>
      {user ? <Route path="/dashboard/:id" element={<Dashboard1/>}/> : <Route path="/dashboard/:id" element={<Navigate replace to="/registration" />} exact/>}

    </Routes>
    {/* <Loader/> */}

  </BrowserRouter>
      </Suspense>
  )
}

export default Home;