import React, { useState, useEffect, useRef } from 'react'
import "./BeatDEuclid.css"
import Navbar from '../Navbar/Navbar.js';
import  WAVES from 'vanta/dist/vanta.waves.min'
import Footer from '../Footer/Footer';
const BeatDEuclid = (props) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x96828,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <>
    <Navbar/>
    <div className='eventsinfo' >
    <div className='eventsinfo-body' ref={myRef}>
    
      
      <div className='py-20'>

      <div className='event-name'>Beat-D-Euclid</div>
    <div className="cs-container" >
      <div className='left-side'>
      <div className="information-container">
        <h1 className="information-title">Information</h1>
        <p className="information-content">
         <i>Coming Soon....</i>
        </p>
      </div>
   
   
      
       <div className="rules-container">
        <h1 className="rules-title">Rules</h1>
        <p className="rules-content">
         <i>Coming Soon....</i>
        </p>
      </div>
      </div>
      <div className='right-side'>
      <div className="problem-container">
      <h1 className="problem-title">Problem Statement</h1>
        <p className="problem-content">
         <i>Coming Soon....</i>
        </p>
      </div>
      <div className="prize-container">
        <h1 className="prize-title">Prize</h1>
        <p className="prize-content">
         <i>Coming Soon....</i>
        </p>
      </div> 
      </div>
    </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>
  );
}

export default BeatDEuclid