import React from 'react'
import {Link} from "react-router-dom";
import './Events.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar.js';
const Events = () => {
  return (
    <>
      <Navbar/>
      <div className='events-body'>
      <div className='heading'>EVENTS</div>
      <section className="cards-wrapper">
      <div className="card-grid-space">
      <div className="events-card event8">
        <div>
          <div className='event-text-cont'><h1>Model Exhibition</h1>
          <p classname="events-para">In this event, Megalith give the opportunity to the participants to bring their creative ideas to life by crafting small-scale models that represent the real-world projects</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='https://drive.google.com/file/d/18hyvTvAlq_hTw1cdh-R2eaaRlDAoUmEr/view?fbclid=IwAR3aseEaHRbZiVcFALJHvBhVDdPJuFEGWA96QSKp0Ry8zQcfSLE5QSP0_64' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
      <div className="card-grid-space">
      <div className="events-card event1">
        <div className='background-events-image'>
        <div className='event-text-cont'>
          <h1 className='event-title'>Green Canvas</h1>
          <p classname="events-para">The purpose of Green Canvas is to encourage participants to come up with innovative and creative ways to mitigate effects of pollution and restore greenery</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='https://drive.google.com/file/d/1HT7wgWTTAbrXW2WSxATWhclx5Uqax-ji/view?fbclid=IwAR0wFtqQISqWmdVzw3EuS1brnDoZOMtYrdLC4hES-ES4VX5AarcYu3SGJjw' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
          </div>
        </div>
      </div>
      <div className="card-grid-space">
      <div className="events-card event4">
        <div >
          <div className='event-text-cont'><h1>Photography</h1>
          <p classname="events-para">This event encourages the participants to showcase their photographic skills that help them capture cherishable moments and to uplift their creative and imaginative skills</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='https://drive.google.com/file/d/1FTFqUTH6BSegtWsIlPS8HIc13Zf1-zs2/view?fbclid=IwAR0R38jeuRczz4KZijuYOe7BXkguKWGnnraqJeaRXL-fdgovsXQAVyATBkc' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="card-grid-space">
      <div className="events-card event6">
        <div>
          <div className='event-text-cont'><h1>Rotolare</h1>
          <p classname="events-para">Craft the Quantum Thrill Quest: an energy-efficient roller coaster track with inventive crests, interactivity, sustainability, safety, personalized rides, nighttime spectacle, and cutting-edge tech for an unparalleled thrilling experience.</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="card-grid-space">
      <div className="events-card event2" >
        <div>
          <div className='event-text-cont'><h1>Cwiz</h1>
          <p classname="events-para">Cwiz is an online quiz event of Megalith IIT Kharagpur that challenges the nerds in the topics of Civil Engineering, Logical Reasoning and General Knowledge</p></div>

          
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="card-grid-space">
      <div className="events-card event3">
        <div>
          <div className='event-text-cont'><h1>Beat-d-Euclid</h1>
          <p classname="events-para">This event encourages the students to showcase their immense talent in the world of geometry with their amazing power of imagination.</p></div>
        
          
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    
    <div className="card-grid-space">
      <div className="events-card event5">
        <div>
          <div className='event-text-cont'><h1>Darkode</h1>
          <p classname="events-para">Megalith provides a unique opportunity to civil engineering students to showcase their coding skills while solving arduous problems in civil engineering.</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    
    <div className="card-grid-space">
      <div className="events-card event7">
        <div>
          <div className='event-text-cont'><h1>Criar</h1>
          <p classname="events-para">Criar, a flagship event in Megalith, is an on-the-spot competition. Participants collaborate overnight, using provided raw materials to build structures, testing teamwork, spontaneity, and optimal material use skills."</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    
    <div className="card-grid-space">
      <div className="events-card event9">
        <div>
          <div className='event-text-cont'><h1>Instridge</h1>
          <p classname="events-para">Build an arch bridge using given materials. Apply basic structural knowledge for a strong and stable structure at an elementary level.</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
    <div className="card-grid-space">
      <div className="events-card event10">
        <div>
          <div className='event-text-cont'><h1>Debate</h1>
          <p classname="events-para">At Megalith, debates enhance confidence, persuasiveness, and quick thinking. Whether you're experienced or new, all are welcome in our inclusive space. Join us for an event shaping articulate and critical thinkers.</p></div>
          <div className="date"></div>
          <div className="tags">
            <Link to ='' target="_blank" rel="noopener noreferrer"><div className="tag">More details...</div></Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  <Footer/>
  </>
  )
}

export default Events